import React from 'react';

export const Card = ({ children, className }) => (
  <div className={`rounded-lg overflow-hidden border shadow-md ${className}`}>
    {children}
  </div>
);

export const CardHeader = ({ children }) => (
  <div className="p-4 border-b bg-gray-50">
    {children}
  </div>
);

export const CardTitle = ({ children }) => (
  <h2 className="text-xl font-bold text-gray-900">{children}</h2>
);

export const CardDescription = ({ children }) => (
  <p className="text-gray-600 text-sm">{children}</p>
);

export const CardContent = ({ children }) => (
  <div className="p-4">
    {children}
  </div>
);
