import React, { useState } from 'react';
import { toast } from 'react-toastify';

const MyCart = ({ cartItems, onPlaceOrder, updateCartItem, setViewCart, userId, tableNumber }) => {
  const [isPlacingOrder, setIsPlacingOrder] = useState(false);

  const calculateTotal = () => {
    return cartItems
      .reduce((total, item) => total + item.price * item.quantity, 0)
      .toFixed(2);
  };

  const handleQuantityChange = (itemId, quantity) => {
    if (quantity < 1) return;
    updateCartItem(itemId, quantity);
    toast.info(`Quantity updated to ${quantity}`);
  };

  const handleRemoveItem = (itemId, itemName) => {
    updateCartItem(itemId, 0);
    toast.info(`${itemName} removed from cart.`);
  };

  const handlePlaceOrder = async () => {
    if (cartItems.length === 0) {
      toast.error('Your cart is empty!');
      return;
    }
  
    setIsPlacingOrder(true);
    try {
      if (!tableNumber) {
        console.warn("⚠️ No table number found, defaulting to takeaway order.");
      }
  
      await onPlaceOrder(userId, tableNumber);  // ✅ Now passing tableNumber
      toast.success('Order placed successfully!');
    } catch (error) {
      toast.error('Failed to place order. Please try again.');
    } finally {
      setIsPlacingOrder(false); // ✅ Ensure button gets re-enabled
    }
  };
  

  return (
    <div className="min-h-screen flex items-center justify-center bg-gradient-to-br from-indigo-600 via-purple-600 to-pink-600 p-4">
      <div className="w-full max-w-md bg-white rounded-2xl shadow-2xl overflow-hidden transform transition-all duration-500 hover:scale-105">
        <div className="p-6">
          <h2 className="text-3xl font-bold mb-6 text-center text-gray-800 flex items-center justify-center">
            <span className="mr-2">🛒</span> My Cart
          </h2>
          
          {cartItems.length === 0 ? (
            <div className="text-center space-y-4">
              <p className="text-gray-500 text-xl">Your cart is empty 🫠</p>
              <button
                onClick={() => setViewCart(false)}
                className="w-full py-3 bg-gradient-to-r from-blue-500 to-purple-600 text-white rounded-lg hover:opacity-90 transition-all"
              >
                Back to Menu
              </button>
            </div>
          ) : (
            <div>
              <div className="bg-gray-50 rounded-lg p-4 mb-4 max-h-80 overflow-y-auto">
                <ul className="divide-y divide-gray-200">
                  {cartItems.map((item) => (
                    <li key={item.id} className="py-4">
                      <div className="flex justify-between items-center">
                        <h3 className="text-lg font-medium text-gray-800">{item.name}</h3>
                        <span className="text-lg font-bold text-blue-600">
                          ₹{(item.price * item.quantity).toFixed(2)}
                        </span>
                      </div>
                      <div className="flex justify-between items-center mt-3">
                        <button
                          onClick={() => handleRemoveItem(item.id, item.name)}
                          className="px-3 py-2 bg-red-500 text-white rounded-lg hover:bg-red-600 transition-all"
                        >
                          Remove
                        </button>
                        <div className="flex items-center border rounded-full overflow-hidden">
                          <button
                            className="px-3 py-2 bg-gray-100 hover:bg-gray-200 transition-colors"
                            onClick={() => handleQuantityChange(item.id, item.quantity - 1)}
                          >
                            -
                          </button>
                          <span className="px-4 py-2 bg-white">{item.quantity}</span>
                          <button
                            className="px-3 py-2 bg-gray-100 hover:bg-gray-200 transition-colors"
                            onClick={() => handleQuantityChange(item.id, item.quantity + 1)}
                          >
                            +
                          </button>
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
              
              <div className="flex justify-between items-center mb-4 px-2">
                <span className="text-xl font-bold text-gray-800">Total:</span>
                <span className="text-2xl font-bold text-green-600">₹{calculateTotal()}</span>
              </div>
              
              <div className="space-y-4">
                <button
                  onClick={handlePlaceOrder}
                  disabled={isPlacingOrder}
                  className={`w-full py-3 rounded-lg transition-all ${
                    isPlacingOrder 
                      ? 'bg-gray-400 cursor-not-allowed' 
                      : 'bg-gradient-to-r from-black-500 to-green-600 hover:opacity-90'
                  } text-white`}
                >
                  {isPlacingOrder ? 'Placing Order...' : '🤤 Place Order'}
                </button>
                
                <button
                  onClick={() => setViewCart(false)}
                  className="w-full py-3 bg-gray-200 text-gray-800 rounded-lg hover:bg-gray-300 transition-all"
                >
                  🔙 Back to Menu
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default MyCart;