import React, { useEffect } from "react";
import { Card, CardHeader, CardContent } from "./ui/card";
import { ArrowLeft } from "lucide-react";

const AboutContactPolicyPages = () => {

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to top when the page loads
  }, []);
  
  return (
    <div className="min-h-screen bg-gray-50 py-8 px-4 md:px-16">
      {/* Back Button */}
      <div className="mb-8">
        <button
          className="flex items-center gap-2 border-2 border-transparent bg-white text-gray-700 rounded-md px-4 py-2 hover:bg-gray-100 hover:border-gradient-to-r hover:from-yellow-400 hover:via-red-500 hover:to-pink-500"
          onClick={() => window.history.back()}
        >
          <ArrowLeft className="h-5 w-5" />
          <span>Back</span>
        </button>
      </div>

      {/* Earn with Us Section */}
      <div className="border-2 border-gradient-to-r from-yellow-400 via-red-500 to-pink-500 text-gray-800 p-8 rounded-lg shadow-md mb-10">
        <h1 className="text-2xl font-bold mb-4 text-red-500">Earn with Us</h1>
        <p className="text-gray-600 text-base">
          For each restaurant you bring to use our App, you will earn a 20% commission which is Rs 100/- per month for each restaurant successfully referred. That means you can earn 1 lakh per month if you have 1000 restaurants under you! There is no limit to it, you can refer as many restaurants as you want.
        </p>
        <p className="text-gray-600 text-base mt-4">
          If you are interested, <a href="https://wa.me/918472958581?text=Hello,%20I%20want%20to%20be%20a%20agent" className="underline font-semibold">click here</a> and send us a message.
        </p>
      </div>

      <div className="container mx-auto max-w-7xl grid gap-8">
        {/* About Us */}
        <Card className="shadow-lg hover:shadow-xl transition-shadow duration-300">
          <CardHeader>
            <h2 className="text-2xl font-semibold">About Us</h2>
          </CardHeader>
          <CardContent>
            <p className="text-gray-600">
              MyWaiter is an Indian-based technology StartUp, committed to
              simplifying restaurant operations and elevating the dining
              experience for customers. Through our platform, restaurants can
              easily create and maintain digital menus, handle online orders,
              and streamline the overall workflow, ensuring both efficiency and
              customer satisfaction.
            </p>
            <p className="text-gray-600 mt-4">
              Our mission is to empower businesses of all sizes with
              innovative tools, helping them adapt to the fast-evolving
              demands of the modern food and beverage industry. With MyWaiter,
              restaurateurs can focus on what they do best—serving great
              food—while we take care of the technology.
            </p>
          </CardContent>
        </Card>

        {/* Contact Us */}
        <Card className="shadow-lg hover:shadow-xl transition-shadow duration-300">
          <CardHeader>
            <h2 className="text-2xl font-semibold">Contact Us</h2>
          </CardHeader>
          <CardContent>
            <p className="text-gray-600">
              Operating Address: Guwahati, Assam
            </p>
            <p className="text-gray-600">Email: 📧 iownwaiterapp@gmail.com</p>
            <p className="text-gray-600">Phone: 📱 +91-8472958581</p>
            <p className="text-gray-600 mt-4">
              We value your feedback and questions. If you need help, have
              business inquiries, or wish to share your suggestions, please
              reach out to us via email or phone.
            </p>
          </CardContent>
        </Card>

        {/* Pricing */}
        <Card className="shadow-lg hover:shadow-xl transition-shadow duration-300">
          <CardHeader>
            <h2 className="text-2xl font-semibold">Pricing</h2>
          </CardHeader>
          <CardContent>
            <p className="text-gray-600">
            💰 Our pricing plan is simple and affordable for all:
            </p>
            <ul className="list-disc pl-6 mt-2 text-gray-600">
              <li>Standard Plan: ₹500/month</li>
            </ul>
            <p className="text-gray-600 mt-4">
              This plan offers a comprehensive set of features to help you manage
              your restaurant's digital presence. For more details or inquiries,
              please contact our sales team.
            </p>
          </CardContent>
        </Card>

        {/* Privacy Policy */}
        <Card className="shadow-lg hover:shadow-xl transition-shadow duration-300">
          <CardHeader>
            <h2 className="text-2xl font-semibold">Privacy Policy</h2>
          </CardHeader>
          <CardContent>
            <p className="text-gray-600">
              🔒 We at MyWaiter respect your privacy and are committed to
              protecting the personal information you share with us. This
              Privacy Policy outlines how we collect, use, and safeguard
              your data in accordance with applicable Indian laws, including
              the Information Technology Act, 2000 and related rules. By
              using our platform, you consent to the terms of this Policy.
            </p>
            <ul className="list-disc pl-6 mt-2 text-gray-600">
              <li>
                <strong>Information We Collect:</strong> We collect
                personal details such as name, contact information, and
                payment details when you sign up or place orders.
              </li>
              <li>
                <strong>Usage of Data:</strong> The collected information
                is used to process orders, improve our services, send
                important updates, and provide customer support.
              </li>
              <li>
                <strong>Data Protection:</strong> We implement robust
                security measures to safeguard your data against
                unauthorized access or misuse.
              </li>
              <li>
                <strong>Cookies:</strong> We use cookies to enhance your
                user experience. You may disable cookies in your browser
                settings; however, some site functionalities may be
                affected.
              </li>
              <li>
                <strong>Third-Party Services:</strong> We may partner with
                third parties for payment processing or analytics. Your data
                will be shared only to the extent necessary and in
                compliance with relevant privacy standards.
              </li>
            </ul>
            <p className="text-gray-600 mt-4">
              If you have any questions about how we handle your personal
              information, please contact us at <em>iownwaiterapp@gmail.com</em>.
            </p>
          </CardContent>
        </Card>

        {/* Terms & Conditions */}
        <Card className="shadow-lg hover:shadow-xl transition-shadow duration-300">
          <CardHeader>
            <h2 className="text-2xl font-semibold">Terms & Conditions</h2>
          </CardHeader>
          <CardContent>
            <p className="text-gray-600">
              📜 These Terms & Conditions ("Terms") govern your use of the
              MyWaiter platform and services. By accessing or using our
              platform, you agree to be bound by these Terms:
            </p>
            <ul className="list-disc pl-6 mt-2 text-gray-600">
              <li>
                <strong>Compliance with Laws:</strong> You must use
                MyWaiter in accordance with all applicable Indian laws and
                regulations.
              </li>
              <li>
                <strong>Account Responsibility:</strong> You are
                responsible for maintaining the confidentiality of your
                account information and for all activities that occur under
                your account.
              </li>
              <li>
                <strong>Prohibited Activities:</strong> Any misuse or
                unauthorized access to the platform, including fraudulent
                or illegal activities, is strictly prohibited.
              </li>
              <li>
                <strong>Liability Disclaimer:</strong> We do not guarantee
                uninterrupted access to our platform and shall not be held
                liable for any direct or indirect losses arising from its
                use or unavailability.
              </li>
              <li>
                <strong>Subscription & Payment:</strong> Subscription fees
                are non-refundable unless stated under our refund policy.
                You agree to pay all applicable charges promptly.
              </li>
              <li>
                <strong>Intellectual Property:</strong> All content,
                logos, and designs on MyWaiter are our exclusive property or
                used with permission. Unauthorized use is prohibited.
              </li>
              <li>
                <strong>Modifications:</strong> MyWaiter reserves the
                right to update or modify these Terms at any time.
                Continued use of the platform after changes have been
                posted constitutes your acceptance of the revised Terms.
              </li>
            </ul>
            <p className="text-gray-600 mt-4">
              If you do not agree to these Terms, please refrain from
              using our platform. For any queries, reach out to us at <em>iownwaiterapp@gmail.com</em>.
            </p>
          </CardContent>
        </Card>

        {/* Cancellation/Refund Policy */}
        <Card className="shadow-lg hover:shadow-xl transition-shadow duration-300">
          <CardHeader>
            <h2 className="text-2xl font-semibold">Cancellation/Refund Policy</h2>
          </CardHeader>
          <CardContent>
            <p className="text-gray-600">
              🔄 We strive to ensure customer satisfaction. If you wish to
              cancel your subscription or request a refund, please note the
              following:
            </p>
            <ul className="list-disc pl-6 mt-2 text-gray-600">
              <li>
                <strong>Cancellation Period:</strong> You can cancel your
                subscription at any time by contacting our support team.
              </li>
              <li>
                <strong>Refund Eligibility:</strong> Refunds are provided
                within 30 days of the purchase date, subject to our review
                and approval.
              </li>
              <li>
                <strong>Processing Time:</strong> Refunds are typically
                processed within 7-14 business days once approved.
              </li>
            </ul>
            <p className="text-gray-600 mt-4">
              If you require more information or have any special
              circumstances, please reach out to us at <em>iownwaiterapp@gmail.com</em>. Our team will do its best to
              assist you.
            </p>
          </CardContent>
        </Card>
      </div>
    </div>
  );
};

export default AboutContactPolicyPages;
