import React, { useState, useEffect } from 'react';
import { Eye, EyeOff, Check, ArrowRight, LoaderCircle } from 'lucide-react';
import { 
  Card, 
  CardContent, 
  CardDescription, 
  CardHeader, 
  CardTitle 
} from './ui/card';
import { Alert, AlertDescription } from './ui/alert';
import RestaurantProfileSetup from './RestaurantProfileSetup';
import { jwtDecode } from 'jwt-decode';

const RestaurantSignup = () => {
  const [formStep, setFormStep] = useState(1);
  const [formData, setFormData] = useState({
    ownerName: '',
    whatsappNumber: '',
    password: '',
    confirmPassword: '',
    otp: ''
  });
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [otpSent, setOtpSent] = useState(false);
  const [error, setError] = useState('');
  const [userId, setUserId] = useState(null);

  useEffect(() => {
    console.log('formStep:', formStep, 'userId:', userId);

    if (formStep === 2 && !userId) {
      setError((prevError) =>
        prevError === '' ? 'User ID is not available. Please try again.' : prevError
      );
    }
  }, [formStep, userId]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    // Prevent spaces in the password field
    if (name === 'password' || name === 'confirmPassword') {
      if (value.includes(' ')) {
        setError('Passwords cannot contain spaces');
        return;
      }
    }

    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
    setError('');
  };

  const validateForm = () => {
    if (!formData.ownerName.trim()) {
      setError('Owner name is required');
      return false;
    }
    if (!formData.whatsappNumber.trim()) {
      setError('WhatsApp number is required');
      return false;
    }
    if (formData.whatsappNumber.length !== 10) {
      setError('Please enter a valid 10-digit number');
      return false;
    }
    if (formData.password.length < 8) {
      setError('Password must be at least 8 characters long');
      return false;
    }
    if (formData.password !== formData.confirmPassword) {
      setError('Passwords do not match');
      return false;
    }
    return true;
  };

  const handleContinue = async (e) => {
    e.preventDefault();

    if (!validateForm()) return;

    setIsLoading(true);
    try {
      const baseUrl = process.env.REACT_APP_API_BASE_URL; // Access the base URL from the environment variable
      const response = await fetch(`${baseUrl}/api/auth/signup`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          ownerName: formData.ownerName.trim(),
          whatsappNumber: formData.whatsappNumber.trim(),
          password: formData.password, // Plain password sent here
        }),
      });
    

      const result = await response.json();
      console.log('Signup response:', result); // Add this log

      if (response.ok) {
        if (!result.token || typeof result.token !== 'string') {
          throw new Error('Invalid token received from server');
        }

        try {
          const decodedToken = jwtDecode(result.token);
          console.log("Decoded Token:", decodedToken);

          // Check token expiration
          const currentTime = Date.now() / 1000; // Current time in seconds
          if (decodedToken.exp < currentTime) {
            setError("Token expired. Please log in again.");
            return;
          }

          localStorage.setItem('authToken', result.token);
          setUserId(decodedToken.userId);
          setFormStep(2);
        } catch (err) {
          console.error('Failed to decode token:', err);
          setError('Invalid token received. Please try signing up again.');
        }
      } else {
        setError(result.error || 'Signup failed');
      }
    } catch (err) {
      console.error('Error during signup:', err);
      setError('An error occurred during signup. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleLogout = () => {
    localStorage.removeItem('authToken');
    setUserId(null);
    setFormStep(1);
  };

  if (formStep === 2) {
    if (userId) {
      return <RestaurantProfileSetup userId={userId} />;
    } else {
      return <div>Loading profile setup...</div>; // Safe fallback
    }
  }

  return (
    <div className="min-h-screen bg-gray-50 flex flex-col items-center justify-center p-4">
      <Card className="w-full max-w-md">
        <CardHeader>
          <CardTitle>🍔My Restaurant</CardTitle>
          <CardDescription>
            Create your account to start setting up your Menu
          </CardDescription>
        </CardHeader>
        <CardContent>
          {error && (
            <Alert variant="destructive" className="mb-4">
              <AlertDescription>{error}</AlertDescription>
            </Alert>
          )}

          <form onSubmit={handleContinue} className="space-y-4">
            <div>
              <label className="text-sm font-medium">Owner Name</label>
              <input
                type="text"
                name="ownerName"
                value={formData.ownerName}
                onChange={handleInputChange}
                className="w-full mt-1 px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                placeholder="Enter your full name"
              />
            </div>

            <div>
              <label className="text-sm font-medium">WhatsApp Number</label>
              <input
                type="tel"
                name="whatsappNumber"
                value={formData.whatsappNumber}
                onChange={handleInputChange}
                className="w-full mt-1 px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                placeholder="Enter 10-digit number"
                maxLength={10}
              />
            </div>

            <div>
              <label className="text-sm font-medium">Password</label>
              <div className="relative">
                <input
                  type={showPassword ? "text" : "password"}
                  name="password"
                  value={formData.password}
                  onChange={handleInputChange}
                  className="w-full mt-1 px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                  placeholder="Create a strong password"
                />
                <button
                  type="button"
                  onClick={() => setShowPassword(!showPassword)}
                  className="absolute right-3 top-1/2 transform -translate-y-1/2"
                >
                  {showPassword ? 
                    <EyeOff className="w-4 h-4 text-gray-500" /> : 
                    <Eye className="w-4 h-4 text-gray-500" />
                  }
                </button>
              </div>
            </div>

            <div>
              <label className="text-sm font-medium">Confirm Password</label>
              <div className="relative">
                <input
                  type={showConfirmPassword ? "text" : "password"}
                  name="confirmPassword"
                  value={formData.confirmPassword}
                  onChange={handleInputChange}
                  className="w-full mt-1 px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                  placeholder="Confirm your password"
                />
                <button
                  type="button"
                  onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                  className="absolute right-3 top-1/2 transform -translate-y-1/2"
                >
                  {showConfirmPassword ? 
                    <EyeOff className="w-4 h-4 text-gray-500" /> : 
                    <Eye className="w-4 h-4 text-gray-500" />
                  }
                </button>
              </div>
            </div>

            <button
              type="submit"
              disabled={isLoading}
              className="w-full py-2 px-4 bg-blue-500 text-white rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 disabled:opacity-50 disabled:cursor-not-allowed flex items-center justify-center space-x-2"
            >
              {isLoading ? (
                <>
                  <LoaderCircle className="w-4 h-4 animate-spin" />
                  <span>Loading...</span>
                </>
              ) : (
                <>
                  <span>😇Continue</span>
                  <ArrowRight className="w-4 h-4" />
                </>
              )}
            </button>
          </form>
        </CardContent>
      </Card>
    </div>
  );
};

export default RestaurantSignup;
