import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'; // For navigation
import { Eye, EyeOff, LoaderCircle } from 'lucide-react';
import { 
  Card, 
  CardContent, 
  CardDescription, 
  CardHeader, 
  CardTitle 
} from './ui/card';
import { Alert, AlertDescription } from './ui/alert';

// Correct import based on jwt-decode version
// For jwt-decode v4.x.x and above
import { jwtDecode } from 'jwt-decode';

// If using jwt-decode v3.x.x or below, use the following import instead:
// import jwtDecode from 'jwt-decode';

const RestaurantLogin = () => {
  const [formData, setFormData] = useState({
    whatsappNumber: '',
    password: '',
  });
  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');

  const navigate = useNavigate(); // Hook for page navigation

  const baseUrl = process.env.REACT_APP_API_BASE_URL;

  useEffect(() => {
    // Optionally, check if the user is already authenticated
    const token = localStorage.getItem('authToken');
    const userId = localStorage.getItem('userId'); // Retrieve userId from localStorage
    if (token) {
      try {
        const decodedToken = jwtDecode(token);
        const currentTime = Date.now() / 1000; // Current time in seconds
        if (decodedToken.exp > currentTime) {
          // Token is valid, redirect to dashboard
          navigate('/restaurant-dashboard');
        } else {
          // Token expired, remove it and userId
          localStorage.removeItem('authToken');
          localStorage.removeItem('userId');
        }
      } catch (err) {
        console.error('Invalid token:', err);
        localStorage.removeItem('authToken');
        localStorage.removeItem('userId');
      }
    }
  }, [navigate]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
    setError('');
  };

  const validateForm = () => {
    if (!formData.whatsappNumber.trim()) {
      setError('WhatsApp number is required');
      return false;
    }
    if (formData.whatsappNumber.length !== 10) {
      setError('Please enter a valid 10-digit number');
      return false;
    }
    if (!formData.password) {
      setError('Password is required');
      return false;
    }
    return true;
  };

  const handleLogin = async (e) => {
    e.preventDefault();

    console.log("Form Data:", formData);

    if (!validateForm()) return;

    setIsLoading(true);
    try {
      const response = await fetch(`${baseUrl.trim()}/api/auth/login`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(formData),
      });

      const result = await response.json();
      console.log('Login Response:', result); // Log the entire response

      if (response.ok) {
        if (!result.token || typeof result.token !== 'string') {
          throw new Error('Invalid token received from server');
        }

        try {
          const decodedToken = jwtDecode(result.token);
          console.log("Decoded Token:", decodedToken);

          // Check token expiration
          const currentTime = Date.now() / 1000; // Current time in seconds
          if (decodedToken.exp < currentTime) {
            setError("Token expired. Please log in again.");
            return;
          }

          // Store the token in localStorage
          localStorage.setItem('authToken', result.token);
          console.log('Token stored in localStorage:', localStorage.getItem('authToken'));

          // Store the userId in localStorage
          if (decodedToken.userId) { // Adjust 'userId' based on your token's structure
            localStorage.setItem('userId', decodedToken.userId);
            console.log('User ID stored in localStorage:', localStorage.getItem('userId'));
          } else {
            console.warn('userId not found in token');
          }

          // Fallback for missing tokens
          if (!localStorage.getItem('authToken')) {
            localStorage.setItem('authToken', result.token);
          }

          // Optionally, you can set user-related state here if needed

          // Redirect to the RestaurantDashboard
          navigate('/restaurant-dashboard');
        } catch (err) {
          console.error('Failed to decode token:', err);
          setError('Invalid token received. Please try logging in again.');
        }
      } else {
        setError(result.error || 'Invalid credentials');
        console.error('Login Error:', result); // Log the error response
      }
    } catch (err) {
      console.error('Error during login:', err);
      setError('Login failed. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleForgotPassword = () => {
    const message = encodeURIComponent(
      "Hi, I forgot my password. Please help me reset it."
    );
    const whatsappLink = `https://wa.me/918472958581?text=${message}`;
    window.location.href = whatsappLink;
  };

  return (
    <div className="min-h-screen bg-gray-50 flex flex-col items-center justify-center p-4">
      <Card className="w-full max-w-md">
        <CardHeader>
          <CardTitle>🍹Restaurant Login</CardTitle>
          <CardDescription>
            Login to access your restaurant dashboard
          </CardDescription>
        </CardHeader>
        <CardContent>
          {error && (
            <Alert variant="destructive" className="mb-4">
              <AlertDescription>{error}</AlertDescription>
            </Alert>
          )}

          <form onSubmit={handleLogin} className="space-y-4">
            <div>
              <label className="text-sm font-medium">WhatsApp Number</label>
              <input
                type="tel"
                name="whatsappNumber"
                value={formData.whatsappNumber}
                onChange={handleInputChange}
                className="w-full mt-1 px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                placeholder="Enter 10-digit number"
                maxLength={10}
              />
            </div>

            <div>
              <label className="text-sm font-medium">Password</label>
              <div className="relative">
                <input
                  type={showPassword ? 'text' : 'password'}
                  name="password"
                  value={formData.password}
                  onChange={handleInputChange}
                  className="w-full mt-1 px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                  placeholder="Enter your password"
                />
                <button
                  type="button"
                  onClick={() => setShowPassword(!showPassword)}
                  className="absolute right-3 top-1/2 transform -translate-y-1/2"
                >
                  {showPassword ? (
                    <EyeOff className="w-4 h-4 text-gray-500" />
                  ) : (
                    <Eye className="w-4 h-4 text-gray-500" />
                  )}
                </button>
              </div>
            </div>

            <button
              type="submit"
              disabled={isLoading}
              className="w-full py-2 px-4 bg-blue-500 text-white rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 disabled:opacity-50 disabled:cursor-not-allowed flex items-center justify-center space-x-2"
            >
              {isLoading ? (
                <>
                  <LoaderCircle className="w-4 h-4 animate-spin" />
                  <span>Logging in...</span>
                </>
              ) : (
                <span>Login</span>
              )}
            </button>

            <button
              type="button"
              onClick={handleForgotPassword}
              disabled={isLoading}
              className="w-full py-2 px-4 text-blue-500 bg-white border border-blue-500 rounded-md hover:bg-blue-50 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 disabled:opacity-50 disabled:cursor-not-allowed"
            >
              Forgot Password?
            </button>
          </form>
        </CardContent>
      </Card>
    </div>
  );
};

export default RestaurantLogin;
