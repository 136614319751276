import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import axios from 'axios';

const MyCart = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [isFetchingOrders, setIsFetchingOrders] = useState(false);
  const [fetchedOrders, setFetchedOrders] = useState([]);

  // Attempt to get WhatsApp number and restaurantId from React Router location state first
  // and if they are not available, fall back to sessionStorage
  const [whatsappNumber, setWhatsappNumber] = useState(
    location.state?.whatsappNumber || sessionStorage.getItem('whatsappNumber')
  );
  const restaurantId =
    location.state?.restaurantId || sessionStorage.getItem('restaurantId');

  useEffect(() => {
    if (whatsappNumber) {
      fetchOrders(whatsappNumber);
    }
  }, [whatsappNumber]);

  const fetchOrders = async (number) => {
    setIsFetchingOrders(true);
    try {
      const response = await axios.get(
        `https://mywaiter-backend.onrender.com/api/order-history/${number}`
      );
      setFetchedOrders(response.data);
      toast.success('Previous orders fetched successfully!');
    } catch (error) {
      if (error.response && error.response.status === 404) {
        toast.info('No previous orders found for this WhatsApp number.');
      } else {
        toast.error('Failed to fetch orders. Please try again.');
      }
    } finally {
      setIsFetchingOrders(false);
    }
  };

  const handleBackToMenu = () => {
    if (restaurantId) {
      // Navigate using React Router
      navigate(`/restaurant/${restaurantId}`);
    } else {
      toast.error('Unable to find the restaurant ID.');
    }
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gradient-to-br from-indigo-600 via-purple-600 to-pink-600 p-4">
      <div className="w-full max-w-md bg-white rounded-2xl shadow-2xl overflow-hidden transform transition-all duration-500 hover:scale-105">
        <div className="p-6">
          <h2 className="text-3xl font-bold mb-6 text-center text-gray-800 flex items-center justify-center">
            <span className="mr-2">😎</span> My Orders
          </h2>

          {isFetchingOrders && (
            <div className="text-center text-gray-500 mb-4 animate-pulse">
              Loading your orders...
            </div>
          )}

          {fetchedOrders.length > 0 ? (
            <div className="bg-gray-50 rounded-lg p-4 max-h-96 overflow-y-auto">
              <ul className="divide-y divide-gray-200">
                {fetchedOrders.map((order) => (
                  <li key={order._id} className="py-4">
                    <div className="space-y-2">
                      <div className="flex justify-between items-center">
                        <span className="font-semibold text-gray-700">
                          Order Details
                        </span>
                        <span
                          className={`px-2 py-1 rounded-full text-xs ${
                            order.status === 'Accepted'
                              ? 'bg-green-100 text-green-800'
                              : order.status === 'Pending'
                              ? 'bg-yellow-100 text-yellow-800'
                              : 'bg-red-100 text-red-800'
                          }`}
                        >
                          {order.status}
                        </span>
                      </div>
                      <p>
                        <strong>Customer:</strong> {order.customerName}
                      </p>
                      <p>
                        <strong>Total:</strong> ₹{order.totalAmount}
                      </p>
                      <p>
                        <strong>Date:</strong>{' '}
                        {new Date(order.placedAt).toLocaleString()}
                      </p>
                      <div className="mt-2">
                        <strong>Items:</strong>
                        <ul className="ml-4 text-sm text-gray-600">
                          {order.items.map((item, index) => (
                            <li key={index}>
                              {item.name} - {item.quantity} × ₹{item.price}
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          ) : (
            !isFetchingOrders && (
              <div className="text-center text-gray-500 py-8">
                No orders found. Start ordering now! 🍽️
              </div>
            )
          )}

          <button
            onClick={handleBackToMenu}
            className="w-full mt-4 py-3 bg-gradient-to-r from-blue-500 to-purple-600 text-white rounded-lg hover:opacity-90 transition-all"
          >
            🔙 Back to Menu
          </button>
        </div>
      </div>
    </div>
  );
};

export default MyCart;
