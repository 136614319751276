// src/components/RestaurantApp.js
import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import {
  Search,
  Star,
  Clock,
  Leaf,
  Beef,
  Play,
  Volume2,
  VolumeX,
  ChevronDown,
  ChevronUp,
} from 'lucide-react';
import { Player } from '@lottiefiles/react-lottie-player';
import { Switch } from './ui/switch';
import loadingAnimation from '../assets/Loading.json';
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from './ui/card';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';

/**
 * -------------------------------------------------------
 * VideoCard component
 * -------------------------------------------------------
 */
const VideoCard = ({ videoUrl, thumbnailUrl, item }) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [isMuted, setIsMuted] = useState(true);
  const videoRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsPlaying(entry.isIntersecting);
      },
      {
        threshold: 0.5,
      }
    );

    if (videoRef.current) {
      observer.observe(videoRef.current);
    }

    return () => {
      if (videoRef.current) {
        observer.unobserve(videoRef.current);
      }
    };
  }, []);

  const getVideoId = (url) => {
    const match = url.match(
      /(?:https?:\/\/)?(?:www\.)?youtube\.com\/embed\/([^?&]+)/
    );
    return match ? match[1] : '';
  };

  return (
    <div ref={videoRef} className="relative w-full" style={{ paddingTop: '100%' }}>
      <div className="absolute top-0 left-0 w-full h-full bg-black rounded-t-lg overflow-hidden">
        {!isPlaying ? (
          <div
            className="w-full h-full bg-cover bg-center cursor-pointer relative"
            style={{ backgroundImage: `url(${thumbnailUrl})` }}
            onClick={() => setIsPlaying(true)}
          >
            <div className="absolute inset-0 bg-black bg-opacity-30 flex items-center justify-center">
              <Play className="w-16 h-16 text-white" />
            </div>
          </div>
        ) : (
          // Adjusted iframe styling for zooming and cropping
          <div className="relative w-full h-full overflow-hidden">
            <iframe
              className="
                absolute top-1/2 left-1/2
                transform -translate-x-1/2 -translate-y-1/2
                w-[177.78%] h-[177.78%]
              "
              src={`${videoUrl}?autoplay=1&mute=${isMuted ? 1 : 0}&loop=1&playlist=${getVideoId(
                videoUrl
              )}&modestbranding=1&rel=0&iv_load_policy=3&enablejsapi=1`}
              allow="autoplay; fullscreen; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              title="item-video"
            />
          </div>
        )}

        {/* Volume Control */}
        <div className="absolute bottom-0 left-0 right-0 p-2 bg-gradient-to-t from-black/50 to-transparent">
          <button
            onClick={() => setIsMuted(!isMuted)}
            className="p-2 text-white rounded-full hover:bg-black/20"
          >
            {isMuted ? <VolumeX className="w-5 h-5" /> : <Volume2 className="w-5 h-5" />}
          </button>
        </div>
      </div>
    </div>
  );
};


/**
 * -------------------------------------------------------
 * ListViewCard component
 * -------------------------------------------------------
 */
const ListViewCard = ({ item }) => {
  return (
    <div
      className={`bg-white border rounded-lg shadow-md p-4 flex flex-col space-y-2 ${
        item.special ? 'border-red-500' : 'border-gray-200'
      }`}
    >
      {/* Line 1: Name and Price */}
      <div className="flex justify-between items-center">
        <div>
          <h2 className="text-lg font-bold flex items-center space-x-2">
            <span>{item.name}</span>
            {item.dietType === 'Vegetarian' ? (
              <Leaf className="w-4 h-4 text-green-500" />
            ) : (
              <Beef className="w-4 h-4 text-red-500" />
            )}
          </h2>
          {item.special && (
            <div className="flex items-center space-x-1 mt-1">
              <Star className="w-5 h-5 text-red-500" fill="currentColor" />
              <span className="text-sm text-red-600 font-bold">Today's Special</span>
            </div>
          )}
        </div>
        <p className="text-xl font-bold text-blue-600">₹{item.price}</p>
      </div>

      {/* Line 2: Cuisine */}
      <p className="text-gray-500">{item.cuisine} Cuisine</p>

      {/* Line 3: Rating and Time */}
      <div className="flex items-center space-x-2">
        <Star className="h-5 w-5 text-yellow-400" />
        <span>{item.rating}</span>
        <Clock className="h-5 w-5 text-gray-400 ml-2" />
        <span>{item.prepTime}</span>
      </div>
    </div>
  );
};

/**
 * -------------------------------------------------------
 * Main RestaurantApp component
 * -------------------------------------------------------
 */
const RestaurantApp = () => {
  const { id } = useParams();

  // State hooks for filtering and view toggling
  const [selectedCategory, setSelectedCategory] = useState('All');
  const [selectedDietType, setSelectedDietType] = useState('All');
  const [selectedCuisine, setSelectedCuisine] = useState('All');
  const [searchQuery, setSearchQuery] = useState('');
  const [restaurantData, setRestaurantData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [isListView, setIsListView] = useState(true);
  const [categories, setCategories] = useState(['All']);
  const [cuisines, setCuisines] = useState(['All']);

  // Fetch restaurant data by ID
  useEffect(() => {
    const fetchRestaurantData = async () => {
      setIsLoading(true);
      setIsError(false);
      try {
        const BASE_URL = process.env.REACT_APP_API_BASE_URL;
        const response = await fetch(`${BASE_URL}/api/public/restaurant-data?id=${id}`);
        if (!response.ok) {
          throw new Error('Failed to fetch restaurant data');
        }
        const data = await response.json();
        if (data && Object.keys(data).length > 0) {
          setRestaurantData(data);

          const dynamicCategories = [
            'All',
            ...new Set(data.menu.map((item) => item.category)),
          ];
          const dynamicCuisines = [
            'All',
            ...new Set(data.menu.map((item) => item.cuisine)),
          ];

          setCategories(dynamicCategories);
          setCuisines(dynamicCuisines);
        } else {
          setIsError(true);
        }
      } catch (error) {
        console.error('Error fetching restaurant data:', error);
        setIsError(true);
      } finally {
        setIsLoading(false);
      }
    };

    fetchRestaurantData();
  }, [id]);

  /**
   * -------------------------------------------------------
   * Filtering & Sorting logic
   * -------------------------------------------------------
   */
  const filteredMenuItems = restaurantData
    ? restaurantData.menu
        .filter((item) => {
          const matchesCategory =
            selectedCategory === 'All' || item.category === selectedCategory;
          const matchesCuisine =
            selectedCuisine === 'All' || item.cuisine === selectedCuisine;
          const matchesSearch =
            item.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
            item.description.toLowerCase().includes(searchQuery.toLowerCase());
          const matchesDietType =
            selectedDietType === 'All' || item.dietType === 'Vegetarian';

          return matchesCategory && matchesCuisine && matchesSearch && matchesDietType;
        })
        .sort((a, b) => b.special - a.special)
    : [];

  // Prepare diet type filters with separate label vs. value
  const dietTypes = [
    { value: 'All', label: 'All' },
    { value: 'Vegetarian', label: 'Veg' },
    { value: 'Non-Vegetarian', label: 'Non-Veg' },
  ];

  return (
    <div className="min-h-screen bg-gray">
      {isLoading ? (
        // Loading State
        <div className="flex items-center justify-center h-screen bg-gray-100">
          <Player
            autoplay
            loop
            src={loadingAnimation}
            style={{ height: '100px', width: '100px' }}
          />
        </div>
      ) : isError ? (
        // Error State
        <div className="flex items-center justify-center h-screen text-red-500">
          Restaurant not found
        </div>
      ) : (
        // Main Content
        <>
          {/* Header */}
          <header className="bg-white shadow-sm">
            <div className="max-w-7xl mx-auto px-4 py-6">
              <div className="flex justify-between items-center">
                <div className="flex items-center space-x-4">
                  <img
                    src={restaurantData.logo}
                    alt={`${restaurantData.name} Logo`}
                    className="h-10"
                  />
                  <h1
                    className="text-2xl font-bold text-gray-800"
                    style={{ fontFamily: 'Georgia, serif' }}
                  >
                    {restaurantData.name}
                  </h1>
                </div>
              </div>
            </div>
          </header>

          {/* Search + Toggle View */}
          <div className="max-w-7xl mx-auto px-4 py-6">
            <div className="flex items-center justify-between">
              {/* Search Bar */}
              <div className="relative w-1/3">
                <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
                <input
                  type="text"
                  placeholder="Search ..."
                  className="w-full pl-10 pr-4 py-3 border border-gray-200 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                  value={searchQuery}
                  onChange={(e) => {
                    setSearchQuery(e.target.value);
                    console.log('Search query updated:', e.target.value);
                  }}
                />
              </div>

              {/* Veg Only Toggle Switch */}
              <div className="flex items-center space-x-2 px-3 py-2 rounded-lg shadow-sm">
                <span className={`text-sm font-medium ${selectedDietType === 'All' ? 'text-black' : 'text-black-400'}`}>
                🔴
                </span>
                <Switch
                  checked={selectedDietType === 'Vegetarian'}
                  onCheckedChange={(checked) =>
                    setSelectedDietType(checked ? 'Vegetarian' : 'All')
                  }
                />
                <span className={`text-sm font-medium ${selectedDietType === 'Vegetarian' ? 'text-black' : 'text-gray-400'}`}>
                🟢
                </span>
              </div>

              {/* List View / Video View Toggle Switch */}
              <div className="flex items-center space-x-3 py-2 rounded-lg shadow-sm">
                <span className="text-sm font-medium text-gray-700">
                  {isListView ? '☰' : '🎬'}
                </span>
                <Switch
                  checked={isListView}
                  onCheckedChange={(checked) => setIsListView(checked)}
                />
              </div>
            </div>
          </div>

          {/* Filter Section */}
          <div className="max-w-7xl mx-auto px-4 space-y-4 flex flex-col items-center">
            <div className="bg-white p-4 w-full md:w-3/4 lg:w-2/3 mx-auto space-y-6 !bg-opacity-0">
              {/* Categories (Horizontally scrollable) */}
              <div className="w-full overflow-x-auto pb-2">
                <div className="inline-flex gap-4">
                  {categories.map((category) => (
                    <button
                      key={category}
                      onClick={() => setSelectedCategory(category)}
                      className={`px-6 py-2 rounded-full text-sm font-medium whitespace-nowrap ${
                        selectedCategory === category
                          ? 'bg-black text-white'
                          : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
                      }`}
                    >
                      {category}
                    </button>
                  ))}
                </div>
              </div>

              {/* Cuisine (Horizontally scrollable) */}
              <div className="w-full overflow-x-auto pb-2">
                <div className="inline-flex gap-4">
                  {cuisines.map((cuisine) => (
                    <button
                      key={cuisine}
                      onClick={() => setSelectedCuisine(cuisine)}
                      className={`px-6 py-2 rounded-full text-sm font-medium whitespace-nowrap ${
                        selectedCuisine === cuisine
                          ? 'bg-black text-white'
                          : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
                      }`}
                    >
                      {cuisine}
                    </button>
                  ))}
                </div>
              </div>
            </div>
          </div>

          {/* New Animated Promotional Card */}
          <div className="max-w-7xl mx-auto px-4 py-4 relative">
            {/* Animated Background Elements */}
            <div className="absolute inset-0 overflow-hidden rounded-lg">
              <div className="absolute top-0 left-0 w-40 h-40 bg-blue-400 rounded-full mix-blend-multiply filter blur-xl opacity-70 animate-blob animation-delay-1000"></div>
              <div className="absolute top-0 right-0 w-40 h-40 bg-pink-400 rounded-full mix-blend-multiply filter blur-xl opacity-70 animate-blob animation-delay-2000"></div>
              <div className="absolute bottom-0 left-1/2 w-40 h-40 bg-purple-400 rounded-full mix-blend-multiply filter blur-xl opacity-70 animate-blob animation-delay-3000"></div>
            </div>
            {/* Promotional Card Content */}
            <div className="relative bg-white/80 backdrop-blur-lg rounded-lg shadow-lg p-6 text-center text-gray-800">
              <p className="text-lg font-semibold">
                Please Visit our Restaurant & Scan the QR Code to Place your Order😊
              </p>
            </div>
          </div>

          {/* Menu Items */}
          <div className="max-w-7xl mx-auto px-4 py-8">
            {isListView ? (
              // List View
              <div className="space-y-4">
                {filteredMenuItems.map((item) => (
                  <ListViewCard key={item.id} item={item} />
                ))}
              </div>
            ) : (
              // Video (Slider) View
              <Swiper
                spaceBetween={16}
                slidesPerView={1}
                breakpoints={{
                  640: { slidesPerView: 1.5 },
                  1024: { slidesPerView: 2.5 },
                }}
              >
                {filteredMenuItems.map((item) => (
                  <SwiperSlide key={item.id}>
                    <Card className="overflow-hidden hover:shadow-lg transition-shadow">
                      <VideoCard
                        videoUrl={item.videoUrl}
                        thumbnailUrl={item.thumbnailUrl}
                        item={item}
                      />
                      <CardHeader>
                        <div className="flex justify-between items-start">
                          <div>
                            <div className="flex items-center space-x-2">
                              <CardTitle className="text-xl font-bold">
                                {item.name}
                              </CardTitle>
                              {item.dietType === 'Vegetarian' ? (
                                <Leaf className="w-4 h-4 text-green-500" />
                              ) : (
                                <Beef className="w-4 h-4 text-red-500" />
                              )}
                            </div>
                            {item.special && (
                              <div className="flex items-center space-x-1 mt-2">
                                <Star
                                  className="w-4 h-4 text-red-500"
                                  fill="currentColor"
                                  stroke="currentColor"
                                />
                                <span className="text-sm text-red-500 font-bold">
                                  Today's Special
                                </span>
                              </div>
                            )}
                            <div className="text-sm text-gray-500 mt-1">
                              {item.cuisine} Cuisine
                            </div>
                            <CardDescription className="mt-2">
                              {item.description}
                            </CardDescription>
                            <div className="flex items-center space-x-2 mt-4">
                              <Star className="h-5 w-5 text-yellow-400 fill-current" />
                              <span className="text-sm text-gray-600">
                                {item.rating}
                              </span>
                              <Clock className="h-5 w-5 text-gray-400 ml-2" />
                              <span className="text-sm text-gray-600">
                                {item.prepTime}
                              </span>
                            </div>
                          </div>
                          <span className="text-xl font-bold text-blue-600">
                            ₹{item.price}
                          </span>
                        </div>
                      </CardHeader>
                    </Card>
                  </SwiperSlide>
                ))}
              </Swiper>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default RestaurantApp;
