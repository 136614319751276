import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import QrScanner from "react-qr-scanner"; // <-- Import from react-qr-scanner
import { Camera, Instagram, MessageCircle, ChevronUp, Sparkles, Info } from "lucide-react";
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "./ui/card";
import {
  Sheet,
  SheetContent,
  SheetDescription,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from "./ui/sheet";
import MainLogo from "../assets/MainLogo.svg";

const LandingPage = () => {
  const [isScanning, setIsScanning] = useState(true);
  const [scanResult, setScanResult] = useState(null);
  const navigate = useNavigate();

  // Dynamic greeting based on time of day
  const getGreeting = () => {
    const currentHour = new Date().getHours();
    if (currentHour < 12) return "☕Hey there! Good Morning";
    if (currentHour < 15) return "☀️Hey there! Good Noon";
    if (currentHour < 18) return "🌇Hey there! Good Afternoon";
    if (currentHour < 21) return "🌙Hey there! Good Evening";
    return "😴Hey there! I'm Sleepy";
  };

  // Navigation for restaurant owners
  const handleSignUp = () => {
    navigate("/restaurant-signup");
  };
  const handleLogin = () => {
    navigate("/restaurant-login");
  };

  const handleAboutUs = () => {
    navigate("/about-us");
  };

  // Start scanning (show the QR scanner)
  const startScan = () => {
    setIsScanning(true);
    setScanResult(null); // Clear previous scan results
  };

  // Handle successful scan
  const handleScan = (data) => {
    if (data) {
      const scannedUrl = data.text;
      console.log("Scanned URL:", scannedUrl);
  
      // Extract restaurant ID and table number correctly
      const urlParts = scannedUrl.split("/");
      
      // Find the index of "restaurant" in the URL to locate the ID
      const restaurantIndex = urlParts.findIndex((part) => part === "restaurant");
      
      if (restaurantIndex !== -1 && urlParts.length > restaurantIndex + 1) {
        const restaurantId = urlParts[restaurantIndex + 1]; // Get restaurant ID
        const tableNumber = urlParts.includes("table") ? urlParts[urlParts.length - 1] : null; // Get table number if exists
  
        console.log("Extracted Restaurant ID:", restaurantId);
        console.log("Extracted Table Number:", tableNumber);
  
        // Navigate with the correct format
        if (tableNumber) {
          navigate(`/restaurant/${restaurantId}/table/${tableNumber}`);
        } else {
          navigate(`/restaurant/${restaurantId}`);
        }
  
        // Stop scanning after a successful read
        setIsScanning(false);
        setScanResult(restaurantId);
      } else {
        console.error("Invalid QR code format. Cannot extract restaurant ID.");
      }
    }
  };
  
  

  // Handle scan errors
  const handleError = (err) => {
    console.error("QR Scanner Error:", err);
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-blue-50 via-purple-50 to-pink-50 flex flex-col">
      {/* Decorative Elements */}
      <div className="fixed inset-0 overflow-hidden pointer-events-none">
        <div className="absolute -top-32 -left-32 w-64 h-64 bg-blue-100 rounded-full mix-blend-multiply filter blur-xl opacity-70 animate-blob"></div>
        <div className="absolute top-0 -right-32 w-64 h-64 bg-purple-100 rounded-full mix-blend-multiply filter blur-xl opacity-70 animate-blob animation-delay-2000"></div>
        <div className="absolute -bottom-32 left-32 w-64 h-64 bg-pink-100 rounded-full mix-blend-multiply filter blur-xl opacity-70 animate-blob animation-delay-4000"></div>
      </div>

      {/* Main Content */}
      <main className="flex-1 flex flex-col items-center justify-center px-4 py-12 w-full text-center">
      {/* Logo Section */}
        <div className="text-center mb-12 animate-fade-in">
          <div className="relative inline-block">
            <div className="absolute inset-0 bg-white-500"></div>
            <img
              src={MainLogo}
              alt="DineQR Logo"
              className="relative w-24 h-24 rounded-full shadow-2xl border-4 border-white"
            />
          </div>
          <h1 className="mt-8 text-5xl font-bold text-black relative overflow-hidden shining-text">
  mywaiter.in
</h1>

          <p className="mt-4 text-lg text-gray-600 flex items-center justify-center gap-2">
            <Sparkles className="w-5 h-5 text-yellow-500" />
            Scan Order Enjoy
            <Sparkles className="w-5 h-5 text-yellow-500" />
          </p>
        </div>

        {/* Scanner Section */}
{/* Scanner Section */}
<Card className="w-full max-w-md bg-white/80 backdrop-blur-lg border-0 shadow-xl mb-12">
  <CardHeader className="text-center">
  <CardTitle>
  <div className="text-lg font-normal italic text-gray-600">{getGreeting()}</div>
</CardTitle>
  </CardHeader>
  <CardContent className="flex items-center justify-center p-6">
    <div className="w-full flex flex-col items-center justify-center">
      {isScanning && (
        <div className="w-72 h-72 bg-gradient-to-br from-blue-500/10 to-purple-500/10 rounded-2xl flex items-center justify-center relative overflow-hidden">
<QrScanner
  delay={300}
  onError={handleError}
  onScan={handleScan}
  constraints={{ video: { facingMode: "environment" } }}
  style={{
    width: "100%",
    height: "100%",
    objectFit: "cover",  // Ensures full square coverage
    borderRadius: "1rem" // Matches container's rounded edges
  }}
/>

        </div>
      )}
    </div>
  </CardContent>
</Card>

      </main>

      {/* Footer */}
      <div className="w-full bg-white/80 backdrop-blur-md shadow-lg mt-auto">
        <div className="max-w-7xl mx-auto px-4 py-6">
          {/* Social Links */}
          <div className="flex justify-center space-x-8 mb-6">
            <a
              href="https://www.instagram.com/mywaiter.in/"
              target="_blank"
              rel="noopener noreferrer"
              className="transform hover:scale-110 transition-transform"
            >
              <div className="p-3 rounded-full bg-gradient-to-br from-purple-600 to-pink-600 text-white">
                <Instagram size={24} />
              </div>
            </a>
            <a
              href="https://wa.me/918472958581"
              target="_blank"
              rel="noopener noreferrer"
              className="transform hover:scale-110 transition-transform"
            >
              <div className="p-3 rounded-full bg-gradient-to-br from-green-500 to-green-600 text-white">
                <MessageCircle size={24} />
              </div>
            </a>
            <button
              onClick={handleAboutUs}
              className="transform hover:scale-110 transition-transform text-gray-700"
            >
              <div className="p-3 rounded-full bg-gradient-to-br from-gray-300 to-gray-400 text-white">
                <Info size={24} />
              </div>
            </button>
          </div>

          {/* Restaurant Login/Signup */}
          <Sheet>
            <SheetTrigger asChild>
              <button className="w-full flex items-center justify-center space-x-2 text-gray-600 hover:text-gray-900 group">
                <span className="text-lg">I'm an Owner</span>
                <ChevronUp className="w-4 h-4 group-hover:transform group-hover:-translate-y-1 transition-transform" />
              </button>
            </SheetTrigger>
            <SheetContent side="bottom" className="bg-white/80 backdrop-blur-lg">
              <SheetHeader>
                <SheetTitle className="text-2xl text-center bg-gradient-to-r from-blue-600 to-purple-600 bg-clip-text text-transparent">
                  I'm an Owner
                </SheetTitle>
                <SheetDescription className="text-center">
                  Login or create a new restaurant account
                </SheetDescription>
              </SheetHeader>
              <div className="grid gap-4 py-8">
                <button
                  onClick={handleLogin}
                  className="w-full py-4 bg-gradient-to-r from-blue-500 to-blue-600 text-white rounded-xl hover:shadow-lg transform hover:-translate-y-0.5 transition-all duration-200"
                >
                  Login
                </button>
                <button
                  onClick={handleSignUp}
                  className="w-full py-4 bg-gradient-to-r from-gray-50 to-gray-100 text-gray-900 rounded-xl hover:shadow-lg transform hover:-translate-y-0.5 transition-all duration-200 border border-gray-200"
                >
                  Sign Up
                </button>
              </div>
            </SheetContent>
          </Sheet>
        </div>
      </div>

      {/* Animations & Styles */}
      <style>{`
.shining-text {
    position: relative;
    display: inline-block;
  }
  
  .shining-text::after {
    content: "";
    position: absolute;
    top: 0;
    left: -100%;
    width: 100%;
    height: 100%;
    background: linear-gradient(120deg, transparent, rgba(255, 255, 255, 0.3), transparent);
    animation: shine 2s infinite linear;
  }

  @keyframes shine {
    0% { left: -100%; }
    100% { left: 100%; }
  }

      @keyframes borderRotate {
  0% { border-color: #ff3e3e; } /* Red */
  25% { border-color:rgb(0, 162, 255); } /* Orange */
  50% { border-color:rgb(255, 0, 0); } /* Green */
  75% { border-color: #2196f3; } /* Blue */
  100% { border-color: #ff3e3e; } /* Back to Red */
}

.animate-border-rotate {
  animation: borderRotate 2s linear infinite;
}
      
        @keyframes blob {
          0% { transform: translate(0px, 0px) scale(1); }
          33% { transform: translate(30px, -50px) scale(1.1); }
          66% { transform: translate(-20px, 20px) scale(0.9); }
          100% { transform: translate(0px, 0px) scale(1); }
        }
        .animate-blob {
          animation: blob 7s infinite;
        }
        .animation-delay-2000 {
          animation-delay: 2s;
        }
        .animation-delay-4000 {
          animation-delay: 4s;
        }

        @keyframes scan {
          0% { transform: translateY(-100%); }
          100% { transform: translateY(100%); }
        }
        .animate-scan {
          animation: scan 1.5s linear infinite;
        }

        .animate-fade-in {
          animation: fadeIn 1s ease-out;
        }
        @keyframes fadeIn {
          from { opacity: 0; transform: translateY(-10px); }
          to { opacity: 1; transform: translateY(0); }
        }
      `}</style>
    </div>
  );
};

export default LandingPage;
