"use client";

import * as React from "react";
import * as SwitchPrimitives from "@radix-ui/react-switch";
import clsx from "clsx"; // Optional: Install with `npm install clsx` if needed.

const Switch = React.forwardRef(function SwitchComponent(
  { className, ...props },
  ref
) {
  return (
    <SwitchPrimitives.Root
      ref={ref}
      className={clsx(
        `
        peer
        inline-flex 
        h-6 
        w-11 
        shrink-0 
        cursor-pointer 
        items-center 
        rounded-full 
        border-2 
        border-transparent 
        transition-colors 
        focus-visible:outline-none 
        focus-visible:ring-2 
        focus-visible:ring-black 
        focus-visible:ring-offset-2 
        focus-visible:ring-offset-background 
        disabled:cursor-not-allowed 
        disabled:opacity-50 
        data-[state=checked]:bg-black  /* ✅ Background is black when ON */
        data-[state=unchecked]:bg-gray-200 /* Background is light gray when OFF */
      `,
        className // Dynamically add custom className
      )}
      {...props}
    >
      <SwitchPrimitives.Thumb
        className={clsx(
          `
          pointer-events-none 
          block 
          h-5 
          w-5 
          rounded-full 
          bg-white /* ✅ Keep white for visibility */
          shadow-lg 
          ring-0 
          transition-transform 
          data-[state=checked]:translate-x-5 
          data-[state=unchecked]:translate-x-0
        `
        )}
      />
    </SwitchPrimitives.Root>
  );
});

Switch.displayName = "Switch";

export { Switch };
