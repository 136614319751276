import React, { useState, useEffect } from 'react';
import { Heart, Gift } from 'lucide-react';

const Lottery = ({ onClose }) => {
  const [isSpinning, setIsSpinning] = useState(false);
  const [hasSpun, setHasSpun] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const [activeIndex, setActiveIndex] = useState(-1);

  const boxes = [
    { type: 'reward', text: 'Special Dinner', icon: Gift },
    { type: 'reward', text: 'Sweet Dessert', icon: Gift },
    { type: 'empty', text: 'Oops' },
    { type: 'empty', text: 'Oops' },
    { type: 'reward', text: 'Love Gift', icon: Gift },
    { type: 'empty', text: 'Oops' },
  ];

  useEffect(() => {
    if (!isSpinning) return; // Only run this effect when spinning is true

    let count = 0;
    const interval = setInterval(() => {
      setActiveIndex(prev => (prev + 1) % boxes.length);
      count++;

      if (count >= 28) {
        clearInterval(interval);

        // Delay the final state update for a smoother transition
        setTimeout(() => {
          setIsSpinning(false);
          setShowMessage(true);

          // Filter out the indices for the empty boxes
          const emptyIndices = boxes.reduce((acc, box, index) => {
            if (box.type === 'empty') {
              acc.push(index);
            }
            return acc;
          }, []);

          // Randomly pick one empty box index
          if (emptyIndices.length > 0) {
            const randomIndex =
              emptyIndices[Math.floor(Math.random() * emptyIndices.length)];
            setActiveIndex(randomIndex);
          }
        }, 500);
      }
    }, 250);

    // Clean up the interval on component unmount or when isSpinning changes
    return () => clearInterval(interval);
  }, [isSpinning]);

  const handleSpin = () => {
    if (!hasSpun) {
      setIsSpinning(true);
      setHasSpun(true);
    }
  };

  return (
    <div className="min-h-screen flex items-center justify-center p-4 bg-gradient-to-b from-red-500 to-red-800">
      <div className="max-w-2xl w-full bg-white rounded-lg shadow-xl p-6 space-y-6">
        {/* Header text (only shows before spinning) */}
        {!hasSpun && (
          <div className="text-center">
            <h2 className="text-2xl font-bold text-red-600 mb-4">
              Valentine's Day Lottery
            </h2>
            <p className="text-gray-600 mb-4">❤️ Check your Love Luck</p>
          </div>
        )}

        {/* Boxes container */}
        <div className="grid grid-cols-2 md:grid-cols-3 gap-4 p-4">
          {boxes.map((box, index) => (
            <div
              key={index}
              className={`
                aspect-square rounded-lg border-2 p-4 flex flex-col items-center justify-center space-y-2
                transition-all duration-200 transform
                ${activeIndex === index 
                  ? 'border-red-500 bg-red-50 scale-105 shadow-lg' 
                  : 'border-red-200 bg-white'
                }
                ${box.type === 'reward' ? 'hover:bg-red-50' : ''}
              `}
            >
              {box.type === 'reward' && (
                <Gift className="w-8 h-8 text-red-500 mb-2" />
              )}
              <span
                className={`text-sm font-medium text-center ${
                  activeIndex === index ? 'text-red-600' : 'text-gray-600'
                }`}
              >
                {box.text}
              </span>
            </div>
          ))}
        </div>

        {/* Spin button */}
        {!hasSpun && (
          <button
            onClick={handleSpin}
            className="w-full py-3 px-6 bg-red-600 text-white rounded-lg hover:bg-red-700 transition-colors flex items-center justify-center space-x-2 disabled:opacity-50 disabled:cursor-not-allowed"
            disabled={isSpinning || hasSpun}
          >
            <Heart className="w-5 h-5" />
            <span>Use my Ticket</span>
          </button>
        )}

        {/* Back Button */}
        <button
          onClick={onClose}
          className="w-full py-3 px-6 bg-gray-300 text-gray-800 rounded-lg hover:bg-gray-400 transition-colors flex items-center justify-center space-x-2 mt-2"
        >
          <span>Back</span>
        </button>

        {/* Message after spinning */}
        {showMessage && (
          <div className="text-center space-y-4 animate-fade-in">
            <p className="text-gray-600">Aww, no prize this time...</p>
            <p className="text-red-600 font-medium">
              Your delicious meal is being prepared with extra love and care! ❤️
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default Lottery;
