// src/components/RestaurantApp.js
import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import {
  Search,
  ShoppingBag,
  Star,
  Clock,
  Leaf,
  Beef,
  Play,
  Volume2,
  VolumeX,
  ChevronDown,
  ChevronUp, // <-- Icons for collapsed/expanded states
} from 'lucide-react'; 
import { Player } from '@lottiefiles/react-lottie-player';
import { Switch } from './ui/switch';
import loadingAnimation from '../assets/Loading.json';
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from './ui/card';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import MyCart from './MyCart';
import OrderDetailsForm from './OrderDetailsForm';

const VideoCard = ({
  videoUrl,
  thumbnailUrl,
  addToCart,
  removeFromCart,
  updateCartItem,
  isInCart,
  cartItems,
  setViewCart,
  item,
}) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [isMuted, setIsMuted] = useState(true);
  const videoRef = useRef(null);

  // Intersection Observer for autoplay
  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsPlaying(entry.isIntersecting);
      },
      {
        threshold: 0.5, // At least 50% visible
      }
    );

    if (videoRef.current) {
      observer.observe(videoRef.current);
    }

    return () => {
      if (videoRef.current) {
        observer.unobserve(videoRef.current);
      }
    };
  }, []);

  // Get current quantity from cartItems
  const cartItem = cartItems.find((cartItem) => cartItem.id === item.id);
  const quantity = cartItem ? cartItem.quantity : 1;

  const handleAddToCart = () => {
    addToCart(item, 1);
  };

  const handleRemoveFromCart = () => {
    removeFromCart(item);
  };

  const handleDecreaseQuantity = () => {
    if (quantity > 1) {
      updateCartItem(item.id, quantity - 1);
    }
  };

  const handleIncreaseQuantity = () => {
    updateCartItem(item.id, quantity + 1);
  };

  const getVideoId = (url) => {
    const match = url.match(/(?:https?:\/\/)?(?:www\.)?youtube\.com\/embed\/([^?&]+)/);
    return match ? match[1] : '';
  };

  return (
    <div
      ref={videoRef}
      className="relative w-full"
      style={{ paddingTop: '100%' }} // 1×1 square container
    >
      <div className="absolute top-0 left-0 w-full h-full bg-black rounded-t-lg overflow-hidden">
        {!isPlaying ? (
          <div
            className="w-full h-full bg-cover bg-center cursor-pointer relative"
            style={{ backgroundImage: `url(${thumbnailUrl})` }}
            onClick={() => setIsPlaying(true)}
          >
            <div className="absolute inset-0 bg-black bg-opacity-30 flex items-center justify-center">
              <Play className="w-16 h-16 text-white" />
            </div>
          </div>
        ) : (
          // Zooming & Cropping Container
          <div className="relative w-full h-full overflow-hidden">
            <iframe
              className="
                absolute top-1/2 left-1/2
                transform -translate-x-1/2 -translate-y-1/2
                w-[177.78%] h-[177.78%]
              "
              src={`${videoUrl}?autoplay=1&mute=${isMuted ? 1 : 0}&loop=1&playlist=${getVideoId(
                videoUrl
              )}&modestbranding=1&rel=0&iv_load_policy=3&enablejsapi=1`}
              allow="autoplay; fullscreen; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              title="item-video"
            />
          </div>
        )}

        {/* Video Controls */}
        <div className="absolute bottom-0 left-0 right-0 p-2 bg-gradient-to-t from-black/50 to-transparent">
          {isInCart && (
            <div className="flex justify-end mb-2">
              <div className="flex items-center space-x-2">
                <button
                  onClick={handleDecreaseQuantity}
                  className="px-2 py-1 bg-gray-200 rounded"
                >
                  -
                </button>
                <span className="px-4 py-1 bg-white rounded border">
                  {quantity}
                </span>
                <button
                  onClick={handleIncreaseQuantity}
                  className="px-2 py-1 bg-gray-200 rounded"
                >
                  +
                </button>
              </div>
            </div>
          )}
          <div className="flex items-center justify-between">
            <button
              onClick={() => setIsMuted(!isMuted)}
              className="p-2 text-white rounded-full hover:bg-black/20 opacity-0"
            >
              {isMuted ? <VolumeX className="w-5 h-5" /> : <Volume2 className="w-5 h-5" />}
            </button>
            <div className="flex items-center space-x-2">
              {isInCart && <span className="text-green-500 font-bold">✅ Added</span>}
              {isInCart ? (
                <button
                  onClick={handleRemoveFromCart}
                  className="px-4 py-2 text-white rounded-lg 
                  bg-gradient-to-r from-pink-500 to-red-500"
                >
                  Remove
                </button>
              ) : (
                <button
                  onClick={handleAddToCart}
                  className="px-4 py-2 text-white rounded-lg 
                  bg-gradient-to-r from-red-500 to-orange-500 
                  hover:from-orange-600 hover:to-purple-600"
                >
                  Add to Cart
                </button>
              )}
              <div
                className="relative bg-black p-1.5 rounded-full"
                style={{ marginRight: '12px' }}
              >
                <ShoppingBag
                  className="h-6 w-6 text-white cursor-pointer hover:opacity-80"
                  onClick={() => setViewCart(true)}
                />
                {cartItems.length > 0 && (
                  <span className="absolute -top-2 -right-2 bg-red-500 text-white 
                    rounded-full w-5 h-5 flex items-center justify-center text-xs"
                  >
                    {cartItems.reduce((acc, item) => acc + item.quantity, 0)}
                  </span>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};


/**
 * -------------------------------------------------------
 * ListViewCard component
 * -------------------------------------------------------
 */
const ListViewCard = ({
  item,
  addToCart,
  removeFromCart,
  updateCartItem,
  isInCart,
  cartItems,
  setViewCart,
  handleToggleSpecial,
}) => {
  // Get current quantity from cartItems
  const cartItem = cartItems.find((cartItem) => cartItem.id === item.id);
  const quantity = cartItem ? cartItem.quantity : 1;

  return (
    <div
      className={`bg-white border rounded-lg shadow-md p-4 flex flex-col space-y-2 ${
        item.special ? 'border-red-500' : 'border-gray-200'
      }`}
    >
      {/* Line 1: Name (left) + Price (right) */}
      <div className="flex justify-between items-center">
        <div>
          <h2 className="text-lg font-bold flex items-center space-x-2">
            <span>{item.name}</span>
            {item.dietType === 'Vegetarian' ? (
              <Leaf className="w-4 h-4 text-green-500" />
            ) : (
              <Beef className="w-4 h-4 text-red-500" />
            )}
          </h2>

          {/* Special Star with "Today's Special" (Now under the name) */}
          {item.special && (
            <div className="flex items-center space-x-1 mt-1">
              <Star className="w-5 h-5 text-red-500" fill="currentColor" />
              <span className="text-sm text-red-600 font-bold">Today's Special</span>
            </div>
          )}
        </div>

        <p className="text-xl font-bold text-blue-600">₹{item.price}</p>
      </div>

      {/* Line 2: Cuisine */}
      <p className="text-gray-500">{item.cuisine} Cuisine</p>

      {/* Line 3: Rating + Time */}
      <div className="flex items-center space-x-2">
        <Star className="h-5 w-5 text-yellow-400" />
        <span>{item.rating}</span>
        <Clock className="h-5 w-5 text-gray-400 ml-2" />
        <span>{item.prepTime}</span>
      </div>

      {/* Line 4: Add/Remove to Cart + Cart Icon */}
      <div className="flex justify-end items-center space-x-4 relative mt-2">
        {isInCart && <span className="text-green-500">✅ Added</span>}
        {isInCart ? (
          <button
            onClick={() => removeFromCart(item)}
            className="px-4 py-2 text-white rounded-lg transition-colors 
                  bg-gradient-to-r from-pink-500 to-red-500"
          >
            Remove
          </button>
        ) : (
          <button
            onClick={() => addToCart(item, 1)}
            className="px-4 py-2 text-white rounded-lg transition-colors 
                  bg-gradient-to-r from-red-500 to-orange-500 
                  hover:from-orange-600 hover:to-purple-600"
          >
            Add to Cart
          </button>
        )}
        <div className="relative cursor-pointer" onClick={() => setViewCart(true)}>
          <ShoppingBag className="h-6 w-6 text-gray-700 hover:text-gray-900" />
          {cartItems.length > 0 && (
            <span className="absolute top-0 right-0 bg-red-500 text-white rounded-full w-5 h-5 flex items-center justify-center text-xs">
              {cartItems.reduce((acc, item) => acc + item.quantity, 0)}
            </span>
          )}
        </div>
      </div>

      {/* Line 5: Quantity Selector (if in cart) */}
      {isInCart && (
        <div className="flex justify-end items-center space-x-2 mt-2">
          <button
            onClick={() => updateCartItem(item.id, quantity - 1)}
            className="px-2 py-1 bg-gray-200 rounded"
            disabled={quantity <= 1}
          >
            -
          </button>
          <span className="px-4 py-1 bg-white rounded border">{quantity}</span>
          <button
            onClick={() => updateCartItem(item.id, quantity + 1)}
            className="px-2 py-1 bg-gray-200 rounded"
          >
            +
          </button>
        </div>
      )}
    </div>
  );
};

/**
 * -------------------------------------------------------
 * Main RestaurantApp component
 * -------------------------------------------------------
 */
const RestaurantApp = () => {
  // Extract id and tableNumber (renamed as paramTableNumber) from URL parameters
  const { id, tableNumber: paramTableNumber } = useParams();

  // Use state for tableNumber & ensure persistence (QR scan, refresh, etc.)
  const [tableNumber, setTableNumber] = useState(
    paramTableNumber || localStorage.getItem("tableNumber") || null
  );

  useEffect(() => {
    if (paramTableNumber) {
      setTableNumber(paramTableNumber);
      localStorage.setItem("tableNumber", paramTableNumber);
    }
  }, [paramTableNumber]);

  // State hooks for various UI elements
  const [selectedCategory, setSelectedCategory] = useState('All');
  const [selectedDietType, setSelectedDietType] = useState('All');
  const [selectedCuisine, setSelectedCuisine] = useState('All');
  const [cartItems, setCartItems] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [viewCart, setViewCart] = useState(false);
  const [viewOrderDetails, setViewOrderDetails] = useState(false);
  const [restaurantData, setRestaurantData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [isListView, setIsListView] = useState(true);
  const [categories, setCategories] = useState(['All']);
  const [cuisines, setCuisines] = useState(['All']);

  // New state for toggling filter menu (if needed)
  const [showFilters, setShowFilters] = useState(false);

  // Fetch restaurant data by ID (including tableNumber)
  useEffect(() => {
    const fetchRestaurantData = async () => {
      setIsLoading(true);
      setIsError(false);
  
      try {
        const BASE_URL = process.env.REACT_APP_API_BASE_URL;
        const response = await fetch(
          `${BASE_URL}/api/public/restaurant-data?id=${id}&tableNumber=${tableNumber}`
        );
  
        if (!response.ok) {
          throw new Error('Failed to fetch restaurant data');
        }
  
        const data = await response.json();
        if (data && Object.keys(data).length > 0) {
          setRestaurantData(data);
          setCategories(['All', ...new Set(data.menu.map((item) => item.category))]);
          setCuisines(['All', ...new Set(data.menu.map((item) => item.cuisine))]);
  
          // Save table number in local storage (if exists)
          if (tableNumber) {
            localStorage.setItem("tableNumber", tableNumber);
          }
        } else {
          setIsError(true);
        }
      } catch (error) {
        console.error('Error fetching restaurant data:', error);
        setIsError(true);
      } finally {
        setIsLoading(false);
      }
    };
  
    if (id) {
      fetchRestaurantData();
    }
  }, [id, tableNumber]);

  /**
   * -------------------------------------------------------
   * Cart & item-handling functions
   * -------------------------------------------------------
   */
  const addToCart = (item, quantity = 1) => {
    console.log('addToCart function called in RestaurantApp');
    console.log('Item to add:', item);
    console.log('Quantity to add:', quantity);

    setCartItems((prevCartItems) => {
      const existingItem = prevCartItems.find(
        (cartItem) => cartItem.id === item.id
      );

      let updatedCart;
      if (existingItem) {
        console.log('Item exists in cart. Updating quantity.');
        updatedCart = prevCartItems.map((cartItem) =>
          cartItem.id === item.id
            ? { ...cartItem, quantity: cartItem.quantity + quantity }
            : cartItem
        );
      } else {
        console.log('Item does not exist in cart. Adding new item.');
        updatedCart = [...prevCartItems, { ...item, quantity }];
      }

      console.log('Updated cart state:', updatedCart);
      return updatedCart;
    });
  };

  const removeFromCart = (item) => {
    console.log('removeFromCart function called in RestaurantApp');
    console.log('Item to remove:', item);
    setCartItems((prevCartItems) =>
      prevCartItems.filter((cartItem) => cartItem.id !== item.id)
    );
    console.log('Item removed:', item);
  };

  const updateCartItem = (itemId, newQuantity) => {
    console.log('updateCartItem function called in RestaurantApp');
    console.log('Item ID to update:', itemId, 'New quantity:', newQuantity);

    setCartItems((prevCartItems) => {
      const updatedCart = prevCartItems
        .map((item) =>
          item.id === itemId ? { ...item, quantity: newQuantity } : item
        )
        .filter((item) => item.quantity > 0); // Remove items with quantity <= 0
      console.log('Updated cart after updateCartItem:', updatedCart);
      return updatedCart;
    });
  };

  /**
   * -------------------------------------------------------
   * Toggle "special" status for an item
   * -------------------------------------------------------
   */
  const handleToggleSpecial = (itemId) => {
    setRestaurantData((prevData) => {
      if (!prevData) return null;
      const updatedMenu = prevData.menu.map((menuItem) => {
        if (menuItem.id === itemId) {
          return { ...menuItem, special: !menuItem.special };
        }
        return menuItem;
      });
      return { ...prevData, menu: updatedMenu };
    });
  };

  /**
   * -------------------------------------------------------
   * placeOrder function
   * -------------------------------------------------------
   */
  const BASE_URL = process.env.REACT_APP_API_BASE_URL;

  const placeOrder = async (customerName, whatsappNumber, tableNumber) => {
    if (cartItems.length === 0) {
      alert('Your cart is empty!');
      return;
    }
  
    const orderPayload = {
      userId: id,
      tableNumber: tableNumber || null, // ✅ Ensure `null` if not provided
      items: cartItems.map((item) => ({
        name: item.name,
        quantity: item.quantity,
        price: item.price,
      })),
      totalAmount: cartItems.reduce(
        (total, item) => total + item.price * item.quantity,
        0
      ),
      customerName,
      whatsappNumber,
    };
  
    try {
      const response = await fetch(`${BASE_URL}/api/orders`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(orderPayload),
      });
  
      if (response.ok) {
        const data = await response.json();
        console.log('Order placed successfully!', data);
        return data;
      } else {
        console.error('Failed to place order.');
        return { success: false, message: 'Failed to place order.' };
      }
    } catch (error) {
      console.error('Error placing order:', error);
      throw new Error('Something went wrong while placing the order.');
    }
  };
  

  /**
   * -------------------------------------------------------
   * Filtering & Sorting logic
   * -------------------------------------------------------
   */
  const filteredMenuItems = restaurantData
    ? restaurantData.menu
        .filter((item) => {
          const matchesCategory = selectedCategory === 'All' || item.category === selectedCategory;
          const matchesCuisine = selectedCuisine === 'All' || item.cuisine === selectedCuisine;
          const matchesSearch = item.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
                                item.description.toLowerCase().includes(searchQuery.toLowerCase());
          const matchesDietType = selectedDietType === 'All' || item.dietType === 'Vegetarian';

          return matchesCategory && matchesCuisine && matchesSearch && matchesDietType;
        })
        .sort((a, b) => b.special - a.special)
    : [];

  /**
   * -------------------------------------------------------
   * Calculate total item count for cart icon
   * -------------------------------------------------------
   */
  const totalItemCount = cartItems.reduce((acc, item) => acc + item.quantity, 0);

  const fetchOrderStatus = async (orderId) => {
    try {
      const response = await fetch(`${BASE_URL}/api/orders/status/${orderId}`, {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
      });

      if (response.ok) {
        const data = await response.json();
        console.log('Fetched order status:', data);
        return data.status;
      } else {
        console.error('Failed to fetch order status.');
        return null;
      }
    } catch (error) {
      console.error('Error fetching order status:', error);
      return null;
    }
  };

  /**
   * -------------------------------------------------------
   * Render
   * -------------------------------------------------------
   */
  // Prepare diet type filters with separate label vs. value
  const dietTypes = [
    { value: 'All', label: 'All' },
    { value: 'Vegetarian', label: 'Veg' },      // Display "Veg"
    { value: 'Non-Vegetarian', label: 'Non-Veg' }, // Display "Non-Veg"
  ];

  return (
    <div className="min-h-screen bg-gray">
      {viewOrderDetails ? (
        <OrderDetailsForm
          cartItems={cartItems}
          totalAmount={cartItems.reduce(
            (total, item) => total + item.price * item.quantity,
            0
          )}
          onPlaceOrder={placeOrder}
          fetchOrderStatus={fetchOrderStatus}
          goBack={() => setViewOrderDetails(false)}
          restaurantId={id} // Pass restaurant ID
          tableNumber={tableNumber} // ✅ Pass tableNumber to OrderDetailsForm
        />
      ) : viewCart ? (
        <MyCart
          cartItems={cartItems}
          updateCartItem={updateCartItem}
          onPlaceOrder={() => setViewOrderDetails(true)}
          setViewCart={setViewCart}
          userId={id}
          tableNumber={tableNumber} // ✅ Pass tableNumber to MyCart
        />
      ) : isLoading ? (
        // Loading State
        <div className="flex items-center justify-center h-screen bg-gray-100">
          <Player
            autoplay
            loop
            src={loadingAnimation}
            style={{ height: '100px', width: '100px' }}
          />
        </div>
      ) : isError ? (
        // Error State
        <div className="flex items-center justify-center h-screen text-red-500">
          Restaurant not found
        </div>
      ) : (
        // Main Content
        <>
          {/* Header */}
          <header className="bg-white shadow-sm">
            <div className="max-w-7xl mx-auto px-4 py-6">
              <div className="flex justify-between items-center">
                <div className="flex items-center space-x-4">
                  <img
                    src={restaurantData.logo}
                    alt={`${restaurantData.name} Logo`}
                    className="h-10"
                  />
                  <h1
                    className="text-2xl font-bold text-gray-800"
                    style={{ fontFamily: 'Georgia, serif' }}
                  >
                    {restaurantData.name}
                  </h1>
                </div>
              </div>
            </div>
          </header>

          {/* Search + Toggle View */}
          <div className="max-w-7xl mx-auto px-4 py-6">
            <div className="flex items-center justify-between">
              {/* Search Bar */}
              <div className="relative w-1/3">
                <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
                <input
                  type="text"
                  placeholder="Search ..."
                  className="w-full pl-10 pr-4 py-3 border border-gray-200 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                  value={searchQuery}
                  onChange={(e) => {
                    setSearchQuery(e.target.value);
                    console.log('Search query updated:', e.target.value);
                  }}
                />
              </div>

              {/* Veg Only Toggle Switch */}
              <div className="flex items-center space-x-2  px-3 py-2 rounded-lg shadow-sm">
                {/* Left label for Non-Veg */}
                <span className={`text-sm font-medium ${selectedDietType === 'All' ? 'text-black' : 'text-black-400'}`}>
                🔴
                </span>

                {/* Toggle Switch */}
                <Switch
                  checked={selectedDietType === 'Vegetarian'}
                  onCheckedChange={(checked) => setSelectedDietType(checked ? 'Vegetarian' : 'All')}
                />

                {/* Right label for Veg */}
                <span className={`text-sm font-medium ${selectedDietType === 'Vegetarian' ? 'text-black' : 'text-gray-400'}`}>
                🟢
                </span>
              </div>

              {/* List View / Video View Toggle Switch */}
              <div className="flex items-center space-x-3  py-2 rounded-lg shadow-sm">
                <span className="text-sm font-medium text-gray-700">
                  {isListView ? '☰' : '🎬'}
                </span>
                <Switch
                  checked={isListView}
                  onCheckedChange={(checked) => setIsListView(checked)}
                />
              </div>
            </div>
          </div>

          {/* Filter Section (Always Expanded, Without Diet Type) */}
          <div className="max-w-7xl mx-auto px-4 space-y-4 flex flex-col items-center">
            <div className="bg-white p-4 w-full md:w-3/4 lg:w-2/3 mx-auto space-y-6 !bg-opacity-0">
              {/* ------------------------------------------
                  Categories (Horizontally scrollable)
                 ------------------------------------------ */}
              <div className="w-full overflow-x-auto pb-2">
                <div className="inline-flex gap-4">
                  {categories.map((category) => (
                    <button
                      key={category}
                      onClick={() => setSelectedCategory(category)}
                      className={`px-6 py-2 rounded-full text-sm font-medium 
                      whitespace-nowrap
                      ${
                        selectedCategory === category
                          ? 'bg-black text-white'
                          : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
                      }`}
                    >
                      {category}
                    </button>
                  ))}
                </div>
              </div>

              {/* ------------------------------------------
                  Cuisine (Horizontally scrollable; same as Categories)
                 ------------------------------------------ */}
              <div className="w-full overflow-x-auto pb-2">
                <div className="inline-flex gap-4">
                  {cuisines.map((cuisine) => (
                    <button
                      key={cuisine}
                      onClick={() => setSelectedCuisine(cuisine)}
                      className={`px-6 py-2 rounded-full text-sm font-medium 
                      whitespace-nowrap
                      ${
                        selectedCuisine === cuisine
                          ? 'bg-black text-white'
                          : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
                      }`}
                    >
                      {cuisine}
                    </button>
                  ))}
                </div>
              </div>
            </div>
          </div>

          {/* Menu Items */}
          <div className="max-w-7xl mx-auto px-4 py-8">
            {isListView ? (
              // List View
              <div className="space-y-4">
                {filteredMenuItems.map((item) => (
                  <ListViewCard
                    key={item.id}
                    item={item}
                    addToCart={addToCart}
                    removeFromCart={removeFromCart}
                    updateCartItem={updateCartItem}
                    isInCart={cartItems.some(
                      (cartItem) => cartItem.id === item.id
                    )}
                    cartItems={cartItems}
                    setViewCart={setViewCart}
                    handleToggleSpecial={handleToggleSpecial}
                  />
                ))}
              </div>
            ) : (
              // Slider / Card View
              <Swiper
                spaceBetween={16}
                slidesPerView={1}
                breakpoints={{
                  640: { slidesPerView: 1.5 },
                  1024: { slidesPerView: 2.5 },
                }}
              >
                {filteredMenuItems.map((item) => (
                  <SwiperSlide key={item.id}>
                    <Card className="overflow-hidden hover:shadow-lg transition-shadow">
                      <VideoCard
                        videoUrl={item.videoUrl}
                        thumbnailUrl={item.thumbnailUrl}
                        addToCart={addToCart}
                        removeFromCart={removeFromCart}
                        updateCartItem={updateCartItem}
                        isInCart={cartItems.some(
                          (cartItem) => cartItem.id === item.id
                        )}
                        cartItems={cartItems}
                        setViewCart={setViewCart}
                        item={item}
                      />
                      <CardHeader>
                        <div className="flex justify-between items-start">
                          <div>
                            <div className="flex items-center space-x-2">
                              <CardTitle className="text-xl font-bold">
                                {item.name}
                              </CardTitle>
                              {item.dietType === 'Vegetarian' ? (
                                <Leaf className="w-4 h-4 text-green-500" />
                              ) : (
                                <Beef className="w-4 h-4 text-red-500" />
                              )}
                            </div>

                            {item.special && (
                              <div className="flex items-center space-x-1 mt-2">
                                <Star
                                  className="w-4 h-4 text-red-500"
                                  fill="currentColor"
                                  stroke="currentColor"
                                />
                                <span className="text-sm text-red-500 font-bold">
                                  Today's Special
                                </span>
                              </div>
                            )}

                            <div className="text-sm text-gray-500 mt-1">
                              {item.cuisine} Cuisine
                            </div>
                            <CardDescription className="mt-2">
                              {item.description}
                            </CardDescription>
                            <div className="flex items-center space-x-2 mt-4">
                              <Star className="h-5 w-5 text-yellow-400 fill-current" />
                              <span className="text-sm text-gray-600">
                                {item.rating}
                              </span>
                              <Clock className="h-5 w-5 text-gray-400 ml-2" />
                              <span className="text-sm text-gray-600">
                                {item.prepTime}
                              </span>
                            </div>
                          </div>
                          <span className="text-xl font-bold text-blue-600">
                            ₹{item.price}
                          </span>
                        </div>
                      </CardHeader>
                    </Card>
                  </SwiperSlide>
                ))}
              </Swiper>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default RestaurantApp;
