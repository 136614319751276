import React, { useState, useEffect } from 'react';
import {
  Plus,
  Trash2,
  Edit2,
  LoaderCircle,
  Save,
  Image as ImageIcon,
} from 'lucide-react';
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from './ui/card';
import { Alert, AlertDescription } from './ui/alert';

const MenuSetup = () => {
  // Existing states
  const [menuItems, setMenuItems] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [isAddingNew, setIsAddingNew] = useState(false);
  const [editingId, setEditingId] = useState(null);

  // Predefined options
  const [categories, setCategories] = useState([
    'Appetizers',
    'Main Course',
    'Desserts',
    'Beverages',
    'Sides',
    'Snacks',
    'Salad',
    'Soup',
    'Boil Items',
    'Seasonal Special',
    'Dal',
    'Tandoori Items',
    'Dahi/Raita',
    'Rice/Pulao/Biryani'
  ]);
  
  const [cuisines, setCuisines] = useState([
    'Indian',
    'Italian',
    'Chinese',
    'American',
    'Japanese',
    'Thai',
    'Mexican',
    'Global',
    'Assamese'
  ]);
  const [dietTypes, setDietTypes] = useState(['Vegetarian', 'Non-Vegetarian']);

  const [newItem, setNewItem] = useState({
    name: '',
    description: '',
    price: '',
    category: '',
    newCategory: '',
    cuisine: '',
    newCuisine: '',
    dietType: '',
    newDietType: '',
    prepTime: '',
    thumbnailUrl: '',
  });

  // 1. Maintain a Global Recipe Dataset
  const [globalRecipes, setGlobalRecipes] = useState([]);

  // 2. Enhance Item Name Input for Filtering (store filtered recipes)
  const [filteredRecipes, setFilteredRecipes] = useState([]);

  // Fetch menu items on component mount
  useEffect(() => {
    fetchMenuItems();
  }, []);
  
  const fetchMenuItems = async () => {
    setIsLoading(true);
    const token = localStorage.getItem('authToken');
  
    if (!token) {
      setError('You are not logged in. Redirecting to login.');
      window.location.href = '/restaurant-login';
      return;
    }
  
    const baseUrl = process.env.REACT_APP_API_BASE_URL;
    try {
      const response = await fetch(`${baseUrl}/api/menu`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (!response.ok) throw new Error(`Failed to fetch menu items: ${response.statusText}`);
      const data = await response.json();
      setMenuItems(data);
    } catch (error) {
      console.error('Error fetching menu items:', error);
      setError('Failed to load menu items. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };
  
  

  // 1. useEffect to fetch global recipes
  useEffect(() => {
    const fetchGlobalRecipes = async () => {
      const baseUrl = process.env.REACT_APP_API_BASE_URL;
      try {
        const url = `${baseUrl}/api/globalRecipes`;
        console.log('Fetching global recipes from:', url); // Debugging
        const response = await fetch(url);
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json();
        console.log('Fetched global recipes:', data);
        setGlobalRecipes(data);
      } catch (error) {
        console.error('Error fetching global recipes:', error);
      }
    };
    fetchGlobalRecipes();
  }, []);

  // Input change handler for fields other than name
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewItem((prev) => ({
      ...prev,
      [name]: value,
    }));
    setError('');
  };

  // 2. Handle name input change for dynamic filtering
  const handleNameChange = (e) => {
    const input = e.target.value;
    setNewItem((prev) => ({
      ...prev,
      name: input,
    }));
    setError('');

    if (input.trim()) {
      setFilteredRecipes(
        globalRecipes.filter((recipe) =>
          recipe.name.toLowerCase().includes(input.toLowerCase())
        )
      );
    } else {
      setFilteredRecipes([]);
    }
  };

  // Select change handler
  const handleSelectChange = (e) => {
    const { name, value } = e.target;
    if (value === 'custom') {
      setNewItem((prev) => ({
        ...prev,
        [name]: 'custom',
        [`new${name.charAt(0).toUpperCase() + name.slice(1)}`]: '',
      }));
    } else {
      setNewItem((prev) => ({
        ...prev,
        [name]: value,
        [`new${name.charAt(0).toUpperCase() + name.slice(1)}`]: '',
      }));
    }
  };

  // 3. Handle autofill when a recipe suggestion is clicked
  const handleRecipeSelect = (recipe) => {
    setNewItem((prev) => ({
      ...prev,
      name: recipe.name,
      category: recipe.category,
      cuisine: recipe.cuisine,
      dietType: recipe.dietType,
      prepTime: recipe.prepTime,
      description: recipe.description,
      thumbnailUrl: recipe.thumbnailUrl || '',
    }));
    setFilteredRecipes([]);
  };

  // Validate form
  const validateForm = () => {
    if (!newItem.name.trim()) return 'Name is required';
    if (!newItem.description.trim()) return 'Description is required';
    if (!newItem.price.trim() || isNaN(newItem.price))
      return 'Valid price is required';
    if (!newItem.category && !newItem.newCategory) return 'Category is required';
    if (!newItem.cuisine && !newItem.newCuisine) return 'Cuisine is required';
    if (!newItem.dietType && !newItem.newDietType) return 'Diet type is required';
    if (!newItem.prepTime.trim()) return 'Preparation time is required';
    return null;
  };

  // 4. Handle new item addition or updating an existing item
  const handleSubmit = async (e) => {
    e.preventDefault();
  
    const validationError = validateForm();
    if (validationError) {
      setError(validationError);
      return;
    }
  
    // Fetch the JWT token from localStorage
    const token = localStorage.getItem('authToken');
    if (!token) {
      setError('You are not logged in. Redirecting to login.');
      window.location.href = '/login';
      return;
    }
  
    // If the item is not in the global dataset, log a message
    if (!globalRecipes.some((recipe) => recipe.name === newItem.name)) {
      console.log('Adding a new item not in the global database.');
    }
  
    // Prepare the form data
    const formData = {
      name: newItem.name,
      description: newItem.description,
      price: newItem.price,
      category: newItem.category === 'custom' ? newItem.newCategory : newItem.category,
      cuisine: newItem.cuisine === 'custom' ? newItem.newCuisine : newItem.cuisine,
      dietType: newItem.dietType === 'custom' ? newItem.newDietType : newItem.dietType,
      prepTime: newItem.prepTime,
      thumbnailUrl: newItem.thumbnailUrl || '/api/placeholder/300/533',
    };
  
    setIsLoading(true);
  
    try {
      const baseUrl = process.env.REACT_APP_API_BASE_URL;
      const url = editingId
        ? `${baseUrl}/api/menu/${editingId}` // Update an existing item
        : `${baseUrl}/api/menu/add`;        // Add a new item
  
      const method = editingId ? 'PUT' : 'POST';
  
      const response = await fetch(url, {
        method,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`, // Pass token in Authorization header
        },
        body: JSON.stringify(formData),
      });
  
      if (response.ok) {
        await fetchMenuItems();
        setIsAddingNew(false);
        setEditingId(null);
  
        // Reset the form
        setNewItem({
          name: '',
          description: '',
          price: '',
          category: '',
          newCategory: '',
          cuisine: '',
          newCuisine: '',
          dietType: '',
          newDietType: '',
          thumbnailUrl: '',
          prepTime: '',
        });
      } else {
        setError('Failed to save menu item');
      }
    } catch (err) {
      setError('Error saving menu item');
    } finally {
      setIsLoading(false);
    }
  };
  
  // Edit item
  const handleEdit = (item) => {
    setEditingId(item._id);
    setNewItem({
      name: item.name,
      description: item.description,
      price: item.price,
      category: categories.includes(item.category) ? item.category : 'custom',
      newCategory: !categories.includes(item.category) ? item.category : '',
      cuisine: cuisines.includes(item.cuisine) ? item.cuisine : 'custom',
      newCuisine: !cuisines.includes(item.cuisine) ? item.cuisine : '',
      dietType: dietTypes.includes(item.dietType) ? item.dietType : 'custom',
      newDietType: !dietTypes.includes(item.dietType) ? item.dietType : '',
      thumbnailUrl: item.thumbnailUrl,
      prepTime: item.prepTime,
    });
    setIsAddingNew(true);
  };

    // Delete item

    const handleDelete = async (_id) => {
      if (!_id) {
        console.error('Menu item ID (_id) is undefined');
        return;
      }
    
      const token = localStorage.getItem('authToken');
      if (!token) {
        console.error('User not logged in');
        return;
      }
    
      if (!window.confirm('Are you sure you want to delete this menu item?')) return;
    
      setIsLoading(true);
      try {
        const baseUrl = process.env.REACT_APP_API_BASE_URL ;
        const url = `${baseUrl}/api/menu/${_id}`;
        const response = await fetch(url, {
          method: 'DELETE',
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
    
        if (response.ok) {
          console.log('Menu item deleted successfully');
          await fetchMenuItems(); // Refresh menu items
        } else {
          const errorMessage = await response.text();
          console.error('Failed to delete menu item:', errorMessage);
          setError(errorMessage || 'Failed to delete menu item');
        }
      } catch (err) {
        console.error('Error deleting menu item:', err);
        setError('Error deleting menu item');
      } finally {
        setIsLoading(false);
      }
    };
    
      
  <div className="flex justify-between items-center">
  <div>
    <CardTitle>Menu Management</CardTitle>
    <CardDescription>
      Add and manage your restaurant's menu items
    </CardDescription>
  </div>
  <div className="flex space-x-4">
    <button
      onClick={() => (window.location.href = '/restaurant-dashboard')}
      className="py-2 px-4 bg-green-500 text-white rounded-md hover:bg-green-600 flex items-center space-x-2"
    >
      <span>Dashboard</span>
    </button>
    {!isAddingNew && (
      <button
        onClick={() => setIsAddingNew(true)}
        className="py-2 px-4 bg-blue-500 text-white rounded-md hover:bg-blue-600 flex items-center space-x-2"
      >
        <Plus className="w-4 h-4" />
        <span>Add New Item</span>
      </button>
    )}
  </div>
</div>

return (
  <div className="min-h-screen bg-gray-50 p-4">
    <div className="max-w-6xl mx-auto">
      {/* Dashboard Button */}
      <div className="mb-4 flex justify-end">
        <button
          onClick={() => (window.location.href = '/restaurant-dashboard')}
          className="py-2 px-4 bg-red-500 text-white rounded-md hover:bg-green-600"
        >
          Back to Dashboard
        </button>
      </div>

      {/* Menu Management Card */}
      <Card>
        <CardHeader>
          <div className="flex justify-between items-center">
            <div>
              <CardTitle>Menu Management</CardTitle>
              <CardDescription>
                Add and manage your restaurant's menu items
              </CardDescription>
            </div>
            {!isAddingNew && (
              <button
                onClick={() => setIsAddingNew(true)}
                className="py-2 px-4 bg-blue-500 text-white rounded-md hover:bg-blue-600 flex items-center space-x-2"
              >
                <Plus className="w-4 h-4" />
                <span>Add New Item</span>
              </button>
            )}
          </div>
        </CardHeader>
        <CardContent>
            {error && (
              <Alert variant="destructive" className="mb-4">
                <AlertDescription>{error}</AlertDescription>
              </Alert>
            )}

            {/* Add/Edit Form */}
            {isAddingNew && (
              <form onSubmit={handleSubmit} className="mb-8 space-y-4">
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                  {/* Name field with dropdown for global recipe suggestions */}
                  <div>
                    <label className="text-sm font-medium">Name</label>
                    <input
                      type="text"
                      name="name"
                      value={newItem.name}
                      onChange={handleNameChange}
                      className="w-full mt-1 px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                      placeholder="Item name"
                    />
                    {filteredRecipes.length > 0 && (
                      <ul className="bg-white border border-gray-300 rounded-md mt-1 max-h-40 overflow-y-auto">
                        {filteredRecipes.map((recipe) => (
                          <li
                            key={recipe.name}
                            className="px-3 py-2 hover:bg-gray-100 cursor-pointer"
                            onClick={() => handleRecipeSelect(recipe)}
                          >
                            {recipe.name}
                          </li>
                        ))}
                      </ul>
                    )}
                  </div>

                  <div>
                    <label className="text-sm font-medium">Price (₹)</label>
                    <input
                      type="text"
                      name="price"
                      value={newItem.price}
                      onChange={handleInputChange}
                      className="w-full mt-1 px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                      placeholder="Price in rupees"
                    />
                  </div>

                  <div className="md:col-span-2">
                    <label className="text-sm font-medium">Description</label>
                    <textarea
                      name="description"
                      value={newItem.description}
                      onChange={handleInputChange}
                      className="w-full mt-1 px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                      rows="3"
                      placeholder="Item description"
                    />
                  </div>

                  <div>
                    <label className="text-sm font-medium">Category</label>
                    <select
                      name="category"
                      value={newItem.category}
                      onChange={handleSelectChange}
                      className="w-full mt-1 px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                    >
                      <option value="">Select Category</option>
                      {categories.map((cat) => (
                        <option key={cat} value={cat}>
                          {cat}
                        </option>
                      ))}
                      <option value="custom">Add New Category</option>
                    </select>
                    {newItem.category === 'custom' && (
                      <input
                        type="text"
                        name="newCategory"
                        value={newItem.newCategory}
                        onChange={handleInputChange}
                        className="w-full mt-2 px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                        placeholder="Enter new category"
                      />
                    )}
                  </div>

                  <div>
                    <label className="text-sm font-medium">Cuisine</label>
                    <select
                      name="cuisine"
                      value={newItem.cuisine}
                      onChange={handleSelectChange}
                      className="w-full mt-1 px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                    >
                      <option value="">Select Cuisine</option>
                      {cuisines.map((cuisine) => (
                        <option key={cuisine} value={cuisine}>
                          {cuisine}
                        </option>
                      ))}
                      <option value="custom">Add New Cuisine</option>
                    </select>
                    {newItem.cuisine === 'custom' && (
                      <input
                        type="text"
                        name="newCuisine"
                        value={newItem.newCuisine}
                        onChange={handleInputChange}
                        className="w-full mt-2 px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                        placeholder="Enter new cuisine"
                      />
                    )}
                  </div>

                  <div>
                    <label className="text-sm font-medium">Diet Type</label>
                    <select
                      name="dietType"
                      value={newItem.dietType}
                      onChange={handleSelectChange}
                      className="w-full mt-1 px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                    >
                      <option value="">Select Diet Type</option>
                      {dietTypes.map((type) => (
                        <option key={type} value={type}>
                          {type}
                        </option>
                      ))}
                      <option value="custom">Add New Diet Type</option>
                    </select>
                    {newItem.dietType === 'custom' && (
                      <input
                        type="text"
                        name="newDietType"
                        value={newItem.newDietType}
                        onChange={handleInputChange}
                        className="w-full mt-2 px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                        placeholder="Enter new diet type"
                      />
                    )}
                  </div>

                  <div>
                    <label className="text-sm font-medium">Preparation Time</label>
                    <input
                      type="text"
                      name="prepTime"
                      value={newItem.prepTime}
                      onChange={handleInputChange}
                      className="w-full mt-1 px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                      placeholder="e.g. 20-25 min"
                    />
                  </div>
                </div>

                <div className="flex justify-end space-x-4">
                  <button
                    type="button"
                    onClick={() => {
                      setIsAddingNew(false);
                      setEditingId(null);
                      setNewItem({
                        name: '',
                        description: '',
                        price: '',
                        category: '',
                        newCategory: '',
                        cuisine: '',
                        newCuisine: '',
                        dietType: '',
                        newDietType: '',
                        thumbnailUrl: '',
                        prepTime: '',
                      });
                    }}
                    className="py-2 px-4 border border-gray-300 text-gray-700 rounded-md hover:bg-gray-50"
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    disabled={isLoading}
                    className="py-2 px-4 bg-blue-500 text-white rounded-md hover:bg-blue-600 disabled:opacity-50 disabled:cursor-not-allowed flex items-center space-x-2"
                  >
                    {isLoading ? (
                      <>
                        <LoaderCircle className="w-4 h-4 animate-spin" />
                        <span>Saving...</span>
                      </>
                    ) : (
                      <>
                        <Save className="w-4 h-4" />
                        <span>{editingId ? 'Update' : 'Save'}</span>
                      </>
                    )}
                  </button>
                </div>
              </form>
            )}

            {/* Loader when fetching (and not in add/edit mode) */}
            {isLoading && !isAddingNew && (
              <div className="flex justify-center my-4">
                <LoaderCircle className="w-6 h-6 animate-spin text-gray-500" />
              </div>
            )}

            {/* List of Menu Items */}
            {!isAddingNew && menuItems.length > 0 && (
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                {menuItems.map((item) => (
                  <Card key={item._id}>
                    <CardHeader>
                      <CardTitle>{item.name}</CardTitle>
                      <CardDescription>₹{item.price}</CardDescription>
                    </CardHeader>
                    <CardContent>
                      <p>{item.description}</p>
                      <p>
                        <strong>Category:</strong> {item.category}
                      </p>
                      <p>
                        <strong>Cuisine:</strong> {item.cuisine}
                      </p>
                      <p>
                        <strong>Diet Type:</strong> {item.dietType}
                      </p>
                      <p>
                        <strong>Prep Time:</strong> {item.prepTime}
                      </p>
                      <div className="mt-4 flex space-x-2">
                        <button
                          onClick={() => handleEdit(item)}
                          className="py-1 px-2 bg-yellow-500 text-white rounded-md hover:bg-yellow-600 flex items-center space-x-1"
                        >
                          <Edit2 className="w-4 h-4" />
                          <span>Edit</span>
                        </button>
                        <button
                          onClick={() => handleDelete(item._id)}
                          className="py-1 px-2 bg-red-500 text-white rounded-md hover:bg-red-600 flex items-center space-x-1"
                        >
                          <Trash2 className="w-4 h-4" />
                          <span>Delete</span>
                        </button>
                      </div>
                    </CardContent>
                  </Card>
                ))}
              </div>
            )}

            {!isAddingNew && !isLoading && menuItems.length === 0 && (
              <p className="mt-4 text-gray-600">No menu items found.</p>
            )}
          </CardContent>
        </Card>
      </div>
    </div>
  );
};

export default MenuSetup;
