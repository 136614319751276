import React from "react";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import LandingPage from "./components/LandingPage"; // Landing Page component
import RestaurantApp from "./components/RestaurantApp"; // Restaurant App component
import RestaurantPromo from "./components/RestaurantPromo"; // Promotional page component
import RestaurantSignup from "./components/RestaurantSignup"; // Signup flow component
import RestaurantLogin from "./components/RestaurantLogin"; // Login flow component
import RestaurantProfileSetup from "./components/RestaurantProfileSetup"; // Profile setup component
import MenuSetup from "./components/MenuSetup"; // Menu setup component
import RestaurantDashboard from "./components/RestaurantDashboard"; // Dashboard component
import MyProfile from "./components/MyProfile"; // MyProfile component
import AboutUs from "./components/AboutUs"; // About Us component

// Updated PrivateRoute component to prevent repeated redirects
const PrivateRoute = ({ children }) => {
  const userId = localStorage.getItem("userId"); // Retrieve userId directly

  // Avoid unnecessary renders by returning early
  if (!userId) {
    console.warn("No userId found. Redirecting to /restaurant-login...");
    return <Navigate replace to="/restaurant-login" />;
  }

  return children; // Render protected component if userId exists
};

const App = () => {
  return (
    <Router>
      <Routes>
        {/* Landing Page */}
        <Route path="/" element={<LandingPage />} />

        {/* Restaurant App */}
        <Route path="/restaurant/:id" element={<RestaurantApp />} />

        {/* ✅ NEW: Restaurant App with Table Number */}
        <Route path="/restaurant/:id/table/:tableNumber" element={<RestaurantApp />} />

        {/* Restaurant Promo Page */}
        <Route path="/menu/:id" element={<RestaurantPromo />} />

        {/* Signup Flow */}
        <Route path="/restaurant-signup" element={<RestaurantSignup />} />

        {/* Login Flow */}
        <Route path="/restaurant-login" element={<RestaurantLogin />} />

        {/* About Us */}
        <Route path="/about-us" element={<AboutUs />} />

        {/* Profile Setup */}
        <Route path="/profile-setup" element={<RestaurantProfileSetup />} />

        {/* Menu Setup */}
        <Route path="/menu-setup" element={<MenuSetup />} />

        {/* My Profile */}
        <Route
          path="/my-profile"
          element={
            <MyProfile
              cartItems={[]} // Pass your cart items here
              onPlaceOrder={(userId) => console.log("Place order for userId:", userId)} // Replace with actual function
              updateCartItem={(itemId, quantity) => console.log("Update cart item:", itemId, quantity)} // Replace with actual function
              setViewCart={(view) => console.log("Set view cart:", view)} // Replace with actual function
              userId={localStorage.getItem("userId")} // Retrieve userId from local storage
              whatsappNumber={sessionStorage.getItem("whatsappNumber")} // Retrieve WhatsApp number from session storage
            />
          }
        />

        {/* Restaurant Dashboard (Protected Route) */}
        <Route
          path="/restaurant-dashboard"
          element={
            <PrivateRoute>
              <RestaurantDashboard />
            </PrivateRoute>
          }
        />
      </Routes>
    </Router>
  );
};

export default App;
