import React, { useState, useEffect } from 'react';
import { Player } from '@lottiefiles/react-lottie-player';
import loadingAnimation from '../assets/Timer.json';
import cookingAnimation from '../assets/Cooking.json';
import { useNavigate } from 'react-router-dom';
import lotteryGif from '../assets/lottery.gif';
import { 
  ChevronLeftIcon, 
  CheckCircleIcon, 
  XCircleIcon, 
  ClockIcon 
} from 'lucide-react';
import Lottery from './Lottery';

// Get the backend base URL from your environment variable
const BASE_URL = process.env.REACT_APP_API_BASE_URL;

const OrderDetailsForm = ({
  cartItems,
  totalAmount,
  onPlaceOrder,
  fetchOrderStatus,
  goBack,
  restaurantId,
  tableNumber, // ✅ Now receiving tableNumber
}) => {
  const [customerName, setCustomerName] = useState('');
  const [whatsappNumber, setWhatsappNumber] = useState('');
  const [error, setError] = useState('');
  const [isOrderPlaced, setIsOrderPlaced] = useState(false);
  const [timeRemaining, setTimeRemaining] = useState(120);
  const [orderStatus, setOrderStatus] = useState('Pending');
  const [orderId, setOrderId] = useState(null);
  const [timerExpired, setTimerExpired] = useState(false);
  const [showLotteryPopup, setShowLotteryPopup] = useState(false); // State to control lottery popup

  const navigate = useNavigate();

  useEffect(() => {
    const savedName = sessionStorage.getItem('customerName');
    const savedWhatsapp = sessionStorage.getItem('whatsappNumber');
    if (savedName) setCustomerName(savedName);
    if (savedWhatsapp) setWhatsappNumber(savedWhatsapp);
  }, []);

  // Dynamically load the Razorpay SDK if not already loaded
  const loadRazorpay = () => {
    return new Promise((resolve) => {
      if (window.Razorpay) {
        resolve(true);
        return;
      }
      const script = document.createElement('script');
      script.src = 'https://checkout.razorpay.com/v1/checkout.js';
      script.onload = () => resolve(true);
      script.onerror = () => resolve(false);
      document.body.appendChild(script);
    });
  };

  const handlePlaceOrder = async () => {
    // Validate the form fields
    if (!customerName || !whatsappNumber) {
      setError('Please fill in all fields.');
      return;
    }
  
    if (!/^\d{10}$/.test(whatsappNumber)) {
      setError('Please enter a valid 10-digit WhatsApp number.');
      return;
    }
  
    setError('');
    
    try {
      // 1. Ensure Razorpay SDK is loaded dynamically
      const razorpayLoaded = await loadRazorpay();
      if (!razorpayLoaded) {
        throw new Error('Razorpay SDK failed to load.');
      }
  
      // 2. Create a Razorpay order from the backend using BASE_URL
      const paymentResponse = await fetch(`${BASE_URL}/api/create-payment`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        // Razorpay expects amount in paise, so multiply by 100
        body: JSON.stringify({ amount: totalAmount * 100 }),
      });
  
      // Destructure orderId and key from the backend response
      const { orderId: razorpayOrderId, key } = await paymentResponse.json();
  
      // 3. Initialize Razorpay with options
      const options = {
        key, // Razorpay API key
        amount: totalAmount * 100, // Convert to paise
        currency: 'INR',
        name: 'Your Restaurant',
        description: 'Payment for your order',
        order_id: razorpayOrderId, // Razorpay order ID from backend
        handler: async (response) => {
          // 4. Verify payment with backend using BASE_URL
          const verifyResponse = await fetch(`${BASE_URL}/api/verify-payment`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
              razorpay_payment_id: response.razorpay_payment_id,
              razorpay_order_id: response.razorpay_order_id,
              razorpay_signature: response.razorpay_signature,
            }),
          });
  
          const verificationResult = await verifyResponse.json();
  
          if (verificationResult.success) {
            // 5. Proceed with order placement
            setIsOrderPlaced(true);
            sessionStorage.setItem('customerName', customerName);
            sessionStorage.setItem('whatsappNumber', whatsappNumber);
  
            // Now send tableNumber in the order request
            // Updated onPlaceOrder to include tableNumber
            const orderResponse = await onPlaceOrder(customerName, whatsappNumber, tableNumber);
            if (orderResponse && orderResponse.orderId) {
              setOrderId(orderResponse.orderId);
            } else {
              throw new Error('Order ID missing in response');
            }
          } else {
            setError('Payment verification failed.');
          }
        },
        prefill: {
          name: customerName,
          contact: whatsappNumber,
        },
        theme: { color: '#6366F1' },
      };
  
      // 6. Open the Razorpay payment modal
      const razorpay = new window.Razorpay(options);
      razorpay.open();
    } catch (err) {
      console.error('Payment failed:', err);
      setError('Payment failed. Please try again.');
    }
  };

  // Poll for order status updates every 5 seconds once an order is placed
  useEffect(() => {
    if (!orderId || orderStatus !== 'Pending') return;

    const interval = setInterval(async () => {
      try {
        const status = await fetchOrderStatus(orderId);
        if (status && status !== orderStatus) {
          setOrderStatus(status);
        }
      } catch (err) {
        console.error('Error fetching order status:', err);
      }
    }, 5000);

    return () => clearInterval(interval);
  }, [orderId, orderStatus, fetchOrderStatus]);

  // Countdown timer for order confirmation (e.g., pending orders)
  useEffect(() => {
    let timer;
    if (isOrderPlaced && timeRemaining > 0 && orderStatus === 'Pending') {
      timer = setInterval(() => {
        setTimeRemaining((prevTime) => prevTime - 1);
      }, 1000);
    }

    if (timeRemaining === 0 && isOrderPlaced && orderStatus === 'Pending') {
      setTimerExpired(true);
    }

    return () => clearInterval(timer);
  }, [isOrderPlaced, timeRemaining, orderStatus]);

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds.toString().padStart(2, '0')}`;
  };

  const handleGoToCart = () => {
    sessionStorage.setItem('whatsappNumber', whatsappNumber);
    navigate('/my-profile', { state: { whatsappNumber, restaurantId } });
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gradient-to-br from-indigo-600 to-purple-400 p-4">
      <div className="w-full max-w-md bg-white shadow-2xl rounded-2xl overflow-hidden">
        {isOrderPlaced ? (
          <div className="p-6 text-center">
            <div className="flex justify-center mb-4">
              <Player
                autoplay
                loop={orderStatus === 'Pending' || orderStatus === 'Accepted'}
                src={orderStatus === 'Accepted' ? cookingAnimation : loadingAnimation}
                style={{ height: '120px', width: '120px' }}
              />
            </div>

            <div className="mb-4">
              {orderStatus === 'Pending' && (
                <div className="flex items-center justify-center space-x-2 text-yellow-600">
                  <ClockIcon size={24} />
                  <h2 className="text-xl font-semibold">Waiting for Cook to Accept</h2>
                </div>
              )}
              {orderStatus === 'Accepted' && (
                <div className="flex items-center justify-center space-x-2 text-green-600">
                  <CheckCircleIcon size={24} />
                  <h2 className="text-xl font-semibold">Order Accepted</h2>
                </div>
              )}
              {orderStatus === 'Rejected' && (
                <div className="flex items-center justify-center space-x-2 text-red-600">
                  <XCircleIcon size={24} />
                  <h2 className="text-xl font-semibold">Order Rejected</h2>
                </div>
              )}
            </div>

            {orderStatus === 'Pending' && (
              <p className="text-gray-600 mb-4">
                Estimated Time: {formatTime(timeRemaining)}
              </p>
            )}

            {orderStatus === 'Accepted' && (
              <div className="space-y-4">
                <p className="text-black-500 animate-pulse">Cooking your Dishes!</p>
                <button 
                  onClick={handleGoToCart}
                  className="w-full py-3 bg-gradient-to-r from-black-500 to-green-600 hover:opacity-90 text-white rounded-lg hover:bg-indigo-700 transition-colors"
                >
                  Check my Orders
                </button>
                <button
                  onClick={() => setShowLotteryPopup(true)}
                  className="w-full py-3 bg-red-600 text-white rounded-lg hover:bg-red-700 transition-colors"
                >
                  Lottery
                </button>
              </div>
            )}

            {orderStatus === 'Rejected' && (
              <div className="space-y-4">
                <p className="text-red-500">Your order was rejected. Please try again.</p>
                <button 
                  onClick={goBack}
                  className="w-full py-3 bg-gray-200 text-gray-800 rounded-lg hover:bg-gray-300 transition-colors"
                >
                  Go Back
                </button>
              </div>
            )}

            {timerExpired && orderStatus === 'Pending' && (
              <div className="space-y-4">
                <p className="text-red-500">Time is up! Please check your order status.</p>
                <button 
                  onClick={goBack}
                  className="w-full py-3 bg-gray-200 text-gray-800 rounded-lg hover:bg-gray-300 transition-colors"
                >
                  Back
                </button>
              </div>
            )}
          </div>
        ) : (
          <div className="p-6">
            <div className="flex items-center mb-6">
              <button 
                onClick={goBack} 
                className="text-gray-600 hover:text-gray-900 transition-colors"
              >
                <ChevronLeftIcon size={24} />
              </button>
              <h2 className="flex-1 text-center text-2xl font-bold text-gray-800">
                💌Confirm your Order
              </h2>
            </div>

            {/* Lucky Draw Card */}
            <div className="bg-black-200 rounded-xl p-4 mb-6 text-center">
              <img 
                src={lotteryGif} 
                alt="lottery" 
                className="mx-auto w-48 h-24 mb-4 rounded-lg shadow-md" 
              />
              <p className="text-sm text-gray-700">
                <span className="font-bold text-red-600">✨Congratulations!</span>{' '}
                You got a lottery ticket!
              </p>
            </div>

            {error && (
              <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-4" role="alert">
                {error}
              </div>
            )}

            <div className="space-y-4">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Your Name
                </label>
                <input
                  type="text"
                  placeholder="Enter your name"
                  value={customerName}
                  onChange={(e) => setCustomerName(e.target.value)}
                  className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-500"
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  WhatsApp Number
                </label>
                <input
                  type="tel"
                  placeholder="Enter your WhatsApp number"
                  value={whatsappNumber}
                  onChange={(e) => setWhatsappNumber(e.target.value)}
                  className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-500"
                />
              </div>

              <div className="bg-gray-100 rounded-lg p-4 space-y-2">
                <div className="flex justify-between">
                  <span className="text-gray-600">Total Amount:</span>
                  <span className="font-semibold">₹{totalAmount}/-</span>
                </div>
                <div className="flex justify-between">
                  <span className="text-green-600">Food + Ticket:</span>
                  <span className="text-green-600 font-semibold">₹{totalAmount}/-</span>
                </div>
                <div className="flex justify-between border-t pt-2 mt-2">
                  <span className="text-gray-800 font-bold">Amount to Pay Later:</span>
                  <span className="text-gray-800 font-bold">₹0/-</span>
                </div>
              </div>

              <button
                onClick={handlePlaceOrder}
                className="w-full py-3 bg-gradient-to-r from-black-500 to-green-600 hover:opacity-90 text-white rounded-lg hover:bg-indigo-700 transition-colors"
              >
                Pay ₹{totalAmount}/- & Claim Ticket🤑
              </button>
            </div>
          </div>
        )}
      </div>

      {/* Lottery Popup Modal */}
      {showLotteryPopup && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white rounded-lg shadow-xl p-4 relative max-w-md w-full">
            <Lottery onClose={() => setShowLotteryPopup(false)} />
          </div>
        </div>
      )}
    </div>
  );
};

export default OrderDetailsForm;
